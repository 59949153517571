import Cookie from 'js-cookie'
import _ from 'lodash'

import requester from './requester'
import { SET_ACCOUNT, SET_ACTIVE_FB_PAGE, SET_ACTIVE_IG, SET_TOKEN_EXPIRE_AT, SET_PHOTOS } from '../constant'

export const getProfile = token => dispatch => {
  if (token) {
    requester.defaults.headers.common.Authorization = token
  }

  return requester.get('me')
    .then(response => {
      const { data } = response
      const { FacebookPages: pages, InstagramInsta: insta } = data

      if (!_.isEmpty(pages)) {
        let toBeActivePage = null
        _.each(pages, page => {
          if (page.isEnable && page.isActive && page.isConnected) {
            toBeActivePage = page
            return false
          }
        })

        if (!_.isEmpty(toBeActivePage)) {
          let activePagePreviously = Cookie.get('actpg')
          if (_.isEmpty(activePagePreviously)) {
            Cookie.set('actpg', toBeActivePage.id)
          } else {
            activePagePreviously = parseInt(activePagePreviously)
            if (activePagePreviously !== toBeActivePage) {
              const inCookiePageDetail = _.find(pages, { id: activePagePreviously })
              if (inCookiePageDetail && inCookiePageDetail.isEnable && inCookiePageDetail.isActive && inCookiePageDetail.isConnected) {
                toBeActivePage = inCookiePageDetail
              }
            }
          }

          Cookie.set('actpg', toBeActivePage.id)

          dispatch({ type: SET_ACTIVE_FB_PAGE, payload: toBeActivePage })
        }
      } else {
        Cookie.remove('actpg')
      }

      if (!_.isEmpty(insta)) {
        let toBeActiveInsta = null
        _.each(insta, ig => {
          if (ig.isEnable && ig.isActive && ig.isConnected) {
            toBeActiveInsta = ig
            return false
          }
        })

        if (!_.isEmpty(toBeActiveInsta)) {
          let activeInstaPreviously = Cookie.get('actig')

          if (_.isEmpty(activeInstaPreviously)) {
            Cookie.set('actig', toBeActiveInsta.id)
          } else {
            activeInstaPreviously = parseInt(activeInstaPreviously)
            if (activeInstaPreviously !== toBeActiveInsta) {
              const inCookieIgDetail = _.find(insta, { id: activeInstaPreviously })
              if (inCookieIgDetail && inCookieIgDetail.isEnable && inCookieIgDetail.isActive && inCookieIgDetail.isConnected) {
                toBeActiveInsta = inCookieIgDetail
              }
            }
          }

          Cookie.set('actig', toBeActiveInsta.id)

          dispatch({ type: SET_ACTIVE_IG, payload: toBeActiveInsta })
        }
      } else {
        Cookie.remove('actig')
      }

      const photos = [...data.Photos]
      delete data.Photos

      dispatch({ type: SET_PHOTOS, payload: photos })
      dispatch({ type: SET_ACCOUNT, payload: data })

      return data
    })
    .catch(err => {
      console.error(err.toString())
    })
}

export const getTokenExpiredIn = () => dispatch => {
  requester.get('token-refresh-time')
    .then(response => {
      dispatch({ type: SET_TOKEN_EXPIRE_AT, payload: response.data.expiredIn })
    })
    .catch(err => {
      console.error(err.toString())
    })
}

export const getRefreshedToken = () => dispatch => {
  requester.get('refresh-token')
    .then(response => {
      const { token } = response.data

      requester.defaults.headers.common.Authorization = token
      Cookie.set('ssid', token)

      dispatch(getTokenExpiredIn())
    })
    .catch(err => {
      console.error(err.toString())
    })
}

export const updateProfile = account => dispatch => {
  return requester.put('me', account)
    .then(() => {
      dispatch({ type: SET_ACCOUNT, payload: account })
    })
}

export const deleteProfile = () => {
  return requester.delete('me')
}

export const updatePage = whatToUpdate => dispatch => {
  const form = { ...whatToUpdate }
  delete form.id

  return requester.put(`page/${whatToUpdate.id}`, form)
    .then(() => dispatch(getProfile()))
}
