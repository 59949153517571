import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const {parse} = require('../../functions/querystring')

const StyledBox = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    
    .btn-container {
      display: flex;
      justify-content: center;
      margin-top: ${theme.spacing(3)};
      position: relative;
      
      .overlay {
        position: absolute;
        z-index: 10000;
        width: 250px;
        height: 40px;
        // background-color: #000;
        cursor: pointer;
      }
    }
  `}
`

export default function Reconnect (props) {
  const { location, history, account, getProfile, setLoading, getAuthLinkFb, verifyCodeFB, getAuthLinkIg, verifyCodeIg } = props

  const [err, setErr] = useState('Mengesahkan akses anda.. Sila tunggu sebentar')
  const [asErr, setAsErr] = useState(false)
  const [forIg, setForIg] = useState(false)

  const timeout = useRef()

  useEffect(() => {
    const qs = parse(location.search.replace(/^\?/, ''))
    const code = qs.code

    if (_.isEmpty(_.trim(code))) {
      history.push('/')
      return () => {}
    }

    setLoading(true)

    let pageDisconnect = false
    account.FacebookPages.forEach(page => {
      const diff = dayjs().startOf('day').diff(dayjs(page.expiredAt), 'd')
      if (diff <= 0 && page.isActive === true && page.isConnected === false && page.isUnConfirm === false) {
        pageDisconnect = true
      }
    })

    // setForIg(!forIg)

    if (pageDisconnect) {
      verifyCodeFB(code, 'reset')
        .then(() => {
          if (!_.isEmpty(account.InstagramInsta)) {
            setForIg(true)
            setErr('Selesai untuk facebook page. Sila klik semula dibawah untuk kemaskini askes token instagram')
            showLoginBtn()
          } else {
            getProfile()
              .then(() => {})
              .catch(() => {})
              .then(() => history.push('/'))
          }
        })
        .catch(err => {
          showLoginBtn()

          if (err.response && err.response.status === 400) {
            setAsErr(true)
            return setErr(err.response.data.msg)
          }
          console.log(err.response)
        })
        .then(() => setLoading(false))
    } else {
      verifyCodeIg(code, 'reset')
        .then(() => getProfile())
        .then(() => history.push('/'))
        .catch(err => {
          showLoginBtn()

          if (err.response && err.response.status === 400) {
            setAsErr(true)
            return setErr(err.response.data.msg)
          } else if (err.response && err.response.status === 409) {
            return verifyCodeFB(code, 'reset')
              .then(() => {
                setForIg(true)
                setErr('Selesai untuk facebook page. Sila klik semula dibawah untuk kemaskini askes token instagram')
                showLoginBtn()
              })
          }
          console.log(err.response)
        })
        .then(() => setLoading(false))
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function reAuth () {
    const promises = forIg ? getAuthLinkIg('reset') : getAuthLinkFb('reset')

    promises.then(response => {
      window.location = response.data.url
    })
      .catch(err => console.error(err))
  }

  function showLoginBtn () {
    timeout.current = setTimeout(() => {
      const element = document.getElementById('fb-login-container-loader-uniq')
      if (element) {
        FB.XFBML.parse(element)
      }
    }, 600)
  }

  return (
    <StyledBox>
      {
        !_.isEmpty(_.trim(err)) && (
          <Alert severity={asErr? 'error' : 'info'}>{err}</Alert>
        )
      }
      <Box className='btn-container' id='fb-login-container-loader-uniq'>
        <Box className='overlay' onClick={reAuth} />
        <div
          className='fb-login-button'
          data-size='large'
          data-button-type='continue_with'
          data-layout='default'
          data-auto-logout-link='false'
          data-use-continue-as='true'
          data-height='40'
          data-width='240'
        />
      </Box>
    </StyledBox>
  )
}
