import { connect } from 'react-redux'

import Reconnect from '../../modules/reconnect'
import { getAuthLink as getAuthLinkFb, verifyCode as verifyCodeFB } from '../../actions/facebook'
import { getAuthLink as getAuthLinkIg, verifyCode as verifyCodeIg } from '../../actions/instagram'
import { getProfile } from '../../actions/profile'
import { SET_MISC } from '../../constant'

const mapStateToProps = (state) => {
  return {
    account: state.account.detail
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAuthLinkFb,
    getAuthLinkIg,
    verifyCodeFB,
    verifyCodeIg,
    getProfile: () => {
      return dispatch(getProfile())
    },
    setLoading: (showLoading = false) => {
      dispatch({ type: SET_MISC, payload: { showLoading } })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reconnect)
