import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'

import TabHeading from '../../components/tab-heading'
import HeadingBtn from './heading-btn'
import {getInstagramFromFB} from '../../../actions/configuration'
import {SET_MISC, SET_IG_FROM_FB} from '../../../constant'
import {getAuthLink} from '../../../actions/instagram'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const ConfigTab3 = function ConfigTab3(props) {

	const {accountObj, setLoading, saveInstagramFromFB, getAuthLink} = props
	const {detail: account, instagramFromFBFetch, instagramFromFB} = accountObj

	const [instagramToAdd, setInstagramToAdd] = useState([])
	const [instagramToDelete, setInstagramToDelete] = useState([])
	const [msg, setMsg] = useState('')
	const [msgSev, setMsgSev] = useState('info')
	const [otherIg, setOtherIg] = useState([])
	const [activeIg, setActiveIg] = useState([])
	
	useEffect(() => {
		
		if (!instagramFromFBFetch) {
			setLoading(true)
			setMsg('Mendapatkan maklumat instagram anda. Sila tunggu sebentar')
			setMsgSev('info')

			getInstagramFromFB()
				.then(response => {
					const {instagram, belongsToSomeoneElse} = response.data

					setMsg('')
					saveInstagramFromFB(JSON.parse(JSON.stringify(instagram)))
					setOtherIg(belongsToSomeoneElse)

					setLoading(false)
				})
				.catch(err => {
					if (err.response) {
						if (err.response.status === 409) {
							getAuthLink()
								.then(response => {
									const {url} = response.data
									if (!_.isEmpty(_.trim(url))) {
										window.location.href = url
									}
								})
								.catch(err => console.error(err))
							return
						}
					}

					setMsg('Ralat semasa mendapatkan senarai Instagram')
					setMsgSev('error')
					setLoading(false)
				})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instagramFromFBFetch])

	useEffect(() => {

		setActiveIg(JSON.parse(JSON.stringify(account.InstagramInsta)))

	}, [account.InstagramInsta])

	function addNewIG(ig) {
		const instagramToAddClone = JSON.parse(JSON.stringify(instagramToAdd))

		const index = _.findIndex(instagramToAddClone, {igId: ig.igId})
		if (index === -1) {
			instagramToAddClone.push(ig)
		} else {
			instagramToAddClone.splice(index, 1)
		}

		setInstagramToAdd(instagramToAddClone)
	}

	function addToDeleteIg(ig) {
		const instagramToDeleteClone = JSON.parse(JSON.stringify(instagramToDelete))

		const index = _.findIndex(instagramToDeleteClone, {igId: ig.igId})
		if (index === -1) {
			instagramToDeleteClone.push(ig)
		} else {
			instagramToDeleteClone.splice(index, 1)
		}

		setInstagramToDelete(instagramToDeleteClone)
	}

	return (
		<Box>
			<TabHeading
				title="Tetapan Instagram"
				description={'Perbaharui akses token dan penambahan instagram'}
				titleProps={{sm: 12, md: 5, lg: 5}}
				btnContainerProps={{sm: 12, md: 7, lg: 7}}>
				<HeadingBtn
					instagramToAdd={instagramToAdd}
					setInstagramToAdd={setInstagramToAdd}
					instagramToDelete={instagramToDelete}
					setInstagramToDelete={setInstagramToDelete}/>
			</TabHeading>


			{
				!_.isEmpty(_.trim(msg)) && (
					<StyledBox>
						<Alert severity={msgSev}>{msg}</Alert>
					</StyledBox>
				)
			}

			{
				!_.isEmpty(instagramFromFB) && (
					<List disablePadding>
						{
							instagramFromFB.map((detail, i) => {
								let onClick = () => addNewIG(detail)
								let secondText = `IG baru dari Facebook Page : ${detail.pageName}. Sila klik pada IG ini sekiranya ingin menambah sambungan ke Bizsaya`

								const onAddingIndex = _.findIndex(instagramToAdd, {igId: detail.igId})
								if (onAddingIndex !== -1) {
									secondText = 'IG sedia untuk ditambah dan diaktifkan. Sila klik pada SIMPAN untuk mengesahkan penambahan'
									onClick = () => addNewIG(detail)
								}
								const alreadyHaveIndex = _.findIndex(activeIg, {igId: detail.igId})
								if (alreadyHaveIndex !== -1) {
									secondText = 'IG ini telah aktif. Sila klik pada IG ini sekiranya ingin batalkan sambungan IG ini ke Bizsaya'
									onClick = () => addToDeleteIg(detail)
								}
								const onDeleteIndex = _.findIndex(instagramToDelete, {igId: detail.igId})
								if (onDeleteIndex !== -1) {
									secondText = 'IG sedia untuk dibatalkan sambungan ke Bizsaya. Sila klik SIMPAN untuk sahkan pembatalan'
									onClick = () => addToDeleteIg(detail)
								}

								return (
									<ListItem key={i} button onClick={onClick}>
										<ListItemAvatar>
											<Avatar src={`https://graph.facebook.com/v2.10/${detail.pageId}/picture`}/>
										</ListItemAvatar>
										<ListItemText primary={detail.igName} secondary={secondText}/>
									</ListItem>
								)
							})
						}
						{
							otherIg.map((fbPage, i) => {
								return (
									<ListItem key={i}>
										<ListItemAvatar>
											<Avatar src={`https://graph.facebook.com/v2.10/${fbPage.pageId}/picture`}/>
										</ListItemAvatar>
										<ListItemText
											primary={fbPage.igName}
											secondary={`IG ini tidak boleh ditambah kerana diuruskan oleh akaun lain`}/>
									</ListItem>
								)
							})
						}
					</List>
				)
			}
		</Box>
	)

}

const mapStateToProps = (state) => {
	return {
		accountObj: state.account
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		saveInstagramFromFB: payload => {
			dispatch({type: SET_IG_FROM_FB, payload})
		},
		getAuthLink
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfigTab3)