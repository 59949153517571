import React, {useState} from 'react'
import {connect} from 'react-redux'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import Heading from './heading'

const StyledBox = styled(Box)`
  ${({theme}) => `
    
  `}
`

const Tab6 = function Tab6(props) {

	const [openForm, setOpenForm] = useState(false)

	function createNew () {

	}

	function save () {

	}

	return (
		<StyledBox>

			<Heading openForm={openForm} setOpenForm={setOpenForm} createNew={createNew} save={save} />

		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tab6)