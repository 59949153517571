import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'

import {getAuthLink} from '../../actions/facebook'

const ReconnectAlert = function ReconnectAlert(props) {

	const { account, getAuthLink } = props

	const disconnectPagesCol = _.filter(account.FacebookPages, {isConnected: false, isActive: true})
	const disconnectPage = Object.entries(disconnectPagesCol).map(([key, value]) => (value.name))

	function reAuth () {
		getAuthLink('reset')
			.then(response => {
				window.location.href = response.data.url
			})
			.catch(err => {
				console.error(err)
			})
	}

	return (
		<>
			{
				!_.isEmpty(_.filter(account.FacebookPages, {isActive: true, isConnected: false})) && (
					<Alert
						severity={'error'}
						action={
							<Button color="inherit" size="small" variant={'outlined'} onClick={reAuth}>
								Perbaharui akses token
							</Button>
						}
					>
						<AlertTitle>Page Terputus Akses</AlertTitle>
						Facebook Page <i>{disconnectPage.join()}</i> terputus akses dengan Bizsaya. Ini menyebabkan auto reply pada Facebook Page tersebut tergangu. Sila perbaharui akses token anda segera!
					</Alert>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getAuthLink
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReconnectAlert)