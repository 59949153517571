import React, { useEffect, useRef, useState } from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import Pages from './list-pages'
import {confirmPage, getAuthLink, verifyCode} from '../../../actions/facebook'
import {getProfile} from '../../../actions/profile'
import {SET_MISC} from '../../../constant'

const {parse, stringify} = require('../../../functions/querystring')

const StyledDiv = styled(Box)`
  ${({theme}) => `    
    .btn-container {
      display: flex;
      justify-content: center;
      margin-top: ${theme.spacing(3)};
      position: relative;
      
      .overlay {
        position: absolute;
        z-index: 10000;
        width: 250px;
        height: 40px;
        // background-color: #000;
        cursor: pointer;
      }
    }
  `}
`

const StyledAlert = styled(Alert)`
  ${({ theme }) => `
    margin: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)};
    
    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
      font-weight: 700;
    }
  `}
`

const Setup = function Setup(props) {
  const { getAuthLink, verifyCode, location, history, setLoading, getProfile, confirmPage } = props

  const [error, setError] = useState({})
  const [code, setCode] = useState(undefined)
  const [previousPath, setPreviousPath] = useState(undefined)
  const [pages, setPages] = useState([])
  const [onOtherAccountPages, setOnOtherAccountPages] = useState([])

  const timeout = useRef()

  useEffect(() => {
    const qs = parse(location.search.replace(/^\?/, ''))

    setPreviousPath(location.pathname)

    if (_.has(qs, 'error')) {
      if (_.has(qs, 'state') && qs.state === 'reauth') {
        return history.push(`/config?tab=2&${stringify(qs)}`)
      }

      qs.message = 'Proses gagal. Anda tidak membenarkan akses Bizsaya ke Facebook Page anda. Sila cuba semula'
      setError(qs)
      setPages([])
    } else if (_.has(qs, 'code')) {
      setCode(qs.code)
      setPages([])

      verifyCode(qs.code, qs.state)
        .then(response => {
          setCode(undefined)
          if (response.data) {
            if (_.has(qs, 'state') && qs.state === 'reauth') {
              getProfile()
              return history.push('/config?tab=2')
            }
            if (_.has(qs, 'state') && qs.state === 'reset') {
              getProfile()
              return history.push('/')
            }

            if (response.data.skip === true) {
              getProfile()
              history.push('/fb')
            } else {
              setPages(response.data.fbPages)
              setOnOtherAccountPages(response.data.belongsToSomeoneElse)
            }
          }
        })
        .catch(err => {
          setCode(undefined)
          if (err.response && err.response.data) {
            if (_.has(qs, 'state') && qs.state === 'reauth') {
              return history.push(`/config?tab=2&errormsg=${err.response.data.msg}`)
            }

            const { status } = err.response
            if (status === 400) {
              setError({ message: err.response.data.msg })
              showLoginBtn()
            }
          }
        })
    }

    showLoginBtn()

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (previousPath === '/fb-callback' && location.pathname !== '/fb-callback') {
      setError({})
      setCode(undefined)
      setPages([])
      setOnOtherAccountPages([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  function begin () {
    getAuthLink()
      .then(response => {
        if (response.data && response.data.url) {
          window.location = response.data.url
        }
      })
      .catch(err => console.error(err))
  }

  function showLoginBtn () {
    timeout.current = setTimeout(() => {
      const element = document.getElementById('fb-login-container-loader-uniq')
      if (element) {
        FB.XFBML.parse(element)
      }
    }, 600)
  }

  function confirmingPage (activePage) {
    setLoading(true)
    confirmPage(activePage)
      .then(() => {
        getProfile()
        history.push('/fb')
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
  }

  return (
    <StyledDiv>

      {
        !_.isEmpty(error) && (
          <StyledAlert severity={'error'}>{error.message}</StyledAlert>
        )
      }

      {
        !_.isEmpty(code) && _.isEmpty(error) && (
          <StyledAlert severity={'info'}>
            Mengesahkan akses anda.. Sila tunggu sebentar
          </StyledAlert>
        )
      }

      {
        _.isEmpty(code) && _.isEmpty(pages) && (
          <>
            <StyledAlert>
              <AlertTitle>Selamat datang ke Auto Reply Facebook Page Bizsaya</AlertTitle>
              Sebelum anda boleh memulakan tetapan auto reply untuk Facebook Page,
              Anda perlu benarkan akses Bizsaya ke Facebook Page yang akan digunakan untuk auto reply terlebih dahulu.
              Klik button <i>Continue as &quot;nama-akaun-fb-yang-anda-ingin-link&quot;</i> dibawah untuk membenarkan akses.
              <br />
              Sekiranya anda ingin log masuk dengan Facebook akaun yang lain. Sila
              ke <a href='https://facebook.com' target='_blank' rel='noreferrer'>https://facebook.com</a> terlebih
              dahulu, Logout facebook anda kemudian log masuk menggunakan akaun facebook yang anda inginkan.
              <br />
              Untuk makluman anda juga, Bizsaya hanya meminta &quot;Permission&quot; yang khusus untuk auto reply
              di Facebook Page yang anda benarkan akses. Sekiranya &quot;Permission&quot; yang perlu tersebut
              tidak dibenarkan akses oleh anda, Bizsaya tidak akan proses sebarang auto reply daripada
              Facebook Page anda.
              <br />
              Maklumat yang diberikan adalah terhad dan Bizsaya tidak mempunyai sebarang kepentingan terhadap
              maklumat tersebut. Kesemua maklumat tersebut khusus untuk kegunaan automasi di Bizsaya. Selain
              itu juga, Anda boleh &quot;Opt-out&quot; pada bila-bila masa dengan &quot;Padam akaun saya&quot; di modul
              Maklumat akaun ataupun membuang apps Bizsaya
              di <a href='https://www.facebook.com/settings?tab=business_tools&section=active' target='_blank' rel='noreferrer'>Facebook Apps integration anda</a>.
            </StyledAlert>

            <Box className='btn-container' id='fb-login-container-loader-uniq'>
              <Box className='overlay' onClick={begin} />
              <div
                className='fb-login-button'
                data-size='large'
                data-button-type='continue_with'
                data-layout='default'
                data-auto-logout-link='false'
                data-use-continue-as='true'
                data-height='40'
                data-width='240'
              />
            </Box>
          </>
        )
      }

      {
        !_.isEmpty(pages) && (
          <>
            <Pages
              pages={pages}
              onOtherAccountPages={onOtherAccountPages}
              begin={begin}
              confirmPage={confirmingPage}
            />
          </>
        )
      }
    </StyledDiv>
  )
}

const mapStateToProps = (state) => {
	return {
    ...state.facebook,
    account: state.account.detail
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
    getAuthLink,
    verifyCode,
    confirmPage,
    getProfile: () => {
      dispatch(getProfile())
    },
    setLoading: (showLoading = false) => {
      dispatch({ type: SET_MISC, payload: { showLoading } })
    }
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Setup))
