import React, {useState} from 'react'
import {connect} from 'react-redux'
import Cookie from 'js-cookie'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Tooltip from '@mui/material/Tooltip'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'

import ChangePageDialog from './change-page-dialog'

import {SET_ACTIVE_FB_PAGE} from '../../../constant'

const BreadcrumbStyled = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    
    .title-breadcrumb {
      margin-left: ${theme.spacing(2)};
    }
    
    .cursor {
      cursor: pointer;
    }
    
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      .title-breadcrumb {
      	display: none;
      }
    }
  `}
`

const BreadcrumbsHeading = function BreadcrumbsHeading(props) {

	const { account, activePage, activeThisPage } = props
	const { FacebookPages: fbPages } = account

	const [changePage, setChangePage] = useState(false)

	function clickOnPage () {
		if (_.filter(fbPages, {isConnected: true}).length > 1) {
			setChangePage(true)
		}
	}

	function changeActivePage (page) {
		activeThisPage(page)
		setChangePage(false)
	}

	return (
		<>
			<BreadcrumbStyled>
				<Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
					<Typography sx={{ display: 'flex', alignItems: 'center' }} color="inherit">
						<HomeWorkOutlinedIcon />
						<span className={'title-breadcrumb'}>Facebook Page yang sedang tetapkan</span>
					</Typography>
					{
						_.filter(fbPages, {isConnected: true}).length === 1 && (
							<Typography color={'primary'}>{activePage.name}</Typography>
						)
					}
					{
						_.filter(fbPages, {isConnected: true}).length > 1 && (
							<Tooltip arrow title='Klik sini untuk tukar Instragram'>
								<Typography className={'cursor'} color={'primary'} onClick={clickOnPage}>
									{activePage.name}
								</Typography>
							</Tooltip>
						)
					}
				</Breadcrumbs>
			</BreadcrumbStyled>

			{
				changePage && (
					<ChangePageDialog
						fbPages={fbPages}
						activePage={activePage}
						onClose={() => setChangePage(false)}
						changeActivePage={changeActivePage}
					/>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.facebook,
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		activeThisPage: toBeActivePage => {
			Cookie.set('actpg', toBeActivePage.id)
			dispatch({ type: SET_ACTIVE_FB_PAGE, payload: toBeActivePage })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BreadcrumbsHeading)
