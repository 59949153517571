export default theme => `
	height: 100%;
	display: flex;
	flex-direction: column;  

	.tab-wrapper {
		flex-grow: 1;
		display: flex;
		
		@media (max-width: ${theme.breakpoints.values.md}px) {
			flex-direction: column;
			max-width: 100vw;
		}
	
		.tabs {
			flex-shrink: 0;
			padding-left: ${theme.spacing(2)};
			background-color: ${theme.palette.background.paper};
			border-right: 1px solid ${theme.palette.divider};
			
			@media (max-width: ${theme.breakpoints.values.md}px) {
				flex: 0 0 auto;
				border-bottom: 1px solid ${theme.palette.divider};
				border-right: 0px;
				padding: 0px ${theme.spacing(2)};
				
				> div {
					overflow-x: auto !important;
				}
			}
		}
		
	 > div:last-child, ul:last-child {
			flex: 1 1 auto;
	 }
	}
`