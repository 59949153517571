import axios from 'axios'
import _ from 'lodash'

import redirectToPortal from '../functions/redirect-to-portal'

const { hostname } = window.location
let baseURL = 'http://localhost:3000'
if (hostname === 'app.bizsaya.com') {
  baseURL = 'https://api.bizsaya.com'
} else if (hostname === 'app2.bizsaya.com') {
  baseURL = 'https://api2.bizsaya.com'
}

const instance = axios.create({
  baseURL,
  timeout: 60000,
  transformResponse: [(data, response) => {
    if (response['content-type'].match(/application\/json/)) {
      const dataJson = JSON.parse(_.clone(data))
      const errorMessages = [
        'Invalid authentication',
        'Token expired',
        'Token must be provided',
        'Token is invalid',
        'Error get user account detail'
      ]

      if (errorMessages.indexOf(dataJson.msg) !== -1) {
        redirectToPortal()
      } else {
        return JSON.parse(data)
      }
    } else {
      return data
    }
  }]
})

export default instance
