import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'

import DateSelector from './date-selector'
import {generateBill} from '../../../actions/payments'
import {SET_MISC} from '../../../constant'

const TypographyStyled = styled(Typography)`
  ${({theme}) => `
		margin-top: ${theme.spacing(2)};
	`}
`

const AlertStyled = styled(Alert)`
	${({theme}) => `
		a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
    }
	`}
`

const PaymentsDialog = function Payments(props) {

	const {account, open, onClose, toPay, setToPay, setLoading, generateBill} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [pageToChangeDate, setPageToChangeDate] = useState({})
	const [total, setTotal] = useState(0)
	const [number, setNumber] = useState('')
	const [email, setEmail] = useState('')
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {

		let totalNumber = 0
		toPay.forEach(p => {
			totalNumber += p.month
		})

		setTotal(totalNumber * 15)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toPay])

	useEffect(() => {

		setNumber(account.contactNumber || '')
		setEmail(account.email || '')

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		let disable = true
		let phoneValid = false
		let emailValid = false

		if (!_.isEmpty(_.trim(number)) && !_.isEmpty(_.trim(email))) {
			if (/^6?01\d{8,9}$/.test(number)) {
				phoneValid = true
			}

			if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(email)) {
				emailValid = true
			}

			if (phoneValid && emailValid) {
				disable = false
			}
		}

		setDisabled(disable)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [number, email])

	function setMonthPage(month) {
		const toPayClone = JSON.parse(JSON.stringify(toPay))

		const index = _.findIndex(toPayClone, {id: pageToChangeDate.id})
		toPayClone[index].month = month

		setToPay(toPayClone)
		setPageToChangeDate({})
	}

	function beginPayment () {
		alert('Maaf, Bizsaya tidak menerima sebarang bayaran lagi.')
		// setLoading(true)
		// generateBill({pageToPay: toPay, email, number})
		// 	.then(response => {
		// 		if (!_.isEmpty(response.data.url)) {
		// 			window.location.href = response.data.url
		// 		}
		// 	})
		// 	.catch(() => setLoading(false))
	}

	return (
		<>
			<Dialog open={open} onClose={onClose} maxWidth={'sm'} fullScreen={isMobile}>
				<DialogTitle>Pembayaran penambahan Facebook Page</DialogTitle>
				<DialogContent>
					<AlertStyled severity={'info'}>
						<AlertTitle>Cara Pembayaran</AlertTitle>
						Pembayaran adalah secara online.
						Sila pilih berapa bulan untuk Facebook Page tersebut aktif dan klik pada Bayar.
						Emel dan nombor telefon adalah WAJIB di-isi.
						<br/>
						<Typography variant={'caption'}>Pembayaran hanya menggunakan FPX *Tiada kos untuk anda</Typography>
					</AlertStyled>

					<TypographyStyled variant={'subtitle2'}>Facebook Page yang akan dibayar</TypographyStyled>
					<List>
						{
							toPay.map(fbPage => {

								let text = 'Untuk '
								if (fbPage.month === 1) {
									text += 'sebulan'
								} else if (fbPage.month === 12) {
									text += 'setahun'
								} else {
									text += ` ${fbPage.month} bulan`
								}

								return (
									<ListItem
										key={fbPage.id}
										disablePadding
										secondaryAction={
											<Button
												edge="end"
												variant={'outlined'}
												onClick={() => setPageToChangeDate(fbPage)}
												startIcon={<DateRangeOutlinedIcon/>}>
												Tukar Bulan
											</Button>
										}>
										<ListItemText primary={fbPage.name} secondary={text}/>
									</ListItem>
								)
							})
						}
					</List>
					<TypographyStyled variant={'subtitle2'}>Jumlah yang perlu dibayar</TypographyStyled>
					<Typography>RM {total}.00</Typography>
					<br/>
					<TextField
						fullWidth
						label="Nombor telefon"
						variant="outlined"
						margin={'normal'}
						value={number}
						onChange={e => setNumber(e.target.value)}
						InputLabelProps={{ shrink: true }} />
					<TextField
						fullWidth
						label="Emel"
						variant="outlined"
						margin={'normal'}
						value={email}
						onChange={e => setEmail(e.target.value)}
						InputLabelProps={{ shrink: true }} />
				</DialogContent>
				<DialogActions>
					<Button fullWidth={isMobile} variant={'outlined'} color={'warning'} onClick={onClose}>Batal</Button>
					<Button
						fullWidth={isMobile}
						variant={'outlined'}
						color={'secondary'}
						onClick={beginPayment}
						disabled={disabled}>
						Bayar
					</Button>
				</DialogActions>
			</Dialog>
			{
				!_.isEmpty(pageToChangeDate) && (
					<DateSelector
						onClose={() => setPageToChangeDate({})}
						month={pageToChangeDate.month}
						setMonthPage={setMonthPage}/>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		generateBill
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentsDialog)