import React from 'react'

import {styled} from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import dayjs from 'dayjs'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Button from '@mui/material/Button'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'

const StyledDialogContent = styled(DialogContent)`
  ${({ theme }) => `
    padding-right: 0px;  
    padding-left: 0px;
    min-width: 320px;  
  
    .list-page-selector {
      padding-right: 170px;
    }
  `}
`

export default function ChangePage (props) {
  const { pages, pageFbId, changeActivePage, onClose } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open
      fullScreen={isMobile}
      onClose={onClose}
    >
      <DialogTitle>Tukar Facebook Page</DialogTitle>
      <StyledDialogContent>
        <List>
          {
            pages.map(fbPage => {
              if (!fbPage.isEnable) {
                return
              }

              return (
                <ListItem
                  divider
                  className='list-page-selector'
                  key={fbPage.id}
                >
                  <ListItemAvatar>
                    <Avatar src={`https://graph.facebook.com/v2.10/${fbPage.fbId}/picture`} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={fbPage.name}
                    primaryTypographyProps={{ noWrap: true }}
                    secondary={`Tarikh tamat akses ${dayjs(fbPage.expiredAt).format('DD-MM-YYYY')}`}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    {
                      fbPage.fbId !== pageFbId && (
                        <Button
                          color='primary'
                          variant='outlined'
                          startIcon={<CheckCircleOutlinedIcon />}
                          onClick={() => changeActivePage(fbPage)}
                        >
													Paparkan
                        </Button>
                      )
                    }
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          }
        </List>
      </StyledDialogContent>
    </Dialog>
  )
}
