import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import Slide from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'

import {SET_MISC} from '../../constant'

const SnackbarV2 = function SnackbarV2(props) {

	const { snackbarMessage, clearSnackbarMessage } = props

	return (
		<Snackbar
			open={!_.isNull(snackbarMessage) && _.isString(snackbarMessage) && !_.isEmpty(_.trim(snackbarMessage))}
			onClose={clearSnackbarMessage}
			TransitionComponent={props => <Slide {...props} direction='up' />}
			message={_.isString(snackbarMessage) ? snackbarMessage : ''}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			autoHideDuration={5000}
			key={new Date().getTime()}
		/>
	)
}

const mapStateToProps = (state) => {
	return {
		snackbarMessage: state.misc.snackbarMessage
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		clearSnackbarMessage: () => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage: null } })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SnackbarV2)