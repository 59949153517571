import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'


const StyledAlert = styled(Alert)`
  ${({theme}) => `
    margin-bottom: ${theme.spacing(2)};
  `}
`

const InnerTabMessage = function InnerTabMessage(props) {

	const {confData, setConfData, activePage} = props

	const configData = _.find(confData, {pageId: activePage}) || {}

	function handleChange(e) {
		const configDataIndex = _.findIndex(confData, {pageId: activePage})

		if (configDataIndex !== -1) {
			const confDataClone = JSON.parse(JSON.stringify(confData))
			confDataClone[configDataIndex][e.target.name] = e.target.value
			setConfData(confDataClone)
		}
	}

	if (_.isEmpty(configData)) {
		return (
			<Alert severity={'info'}>
				Fungsi tetapan prospek bagi instagram masih belum tersedia
			</Alert>
		)
	}

	return (
		<>
			<StyledAlert severity={'info'}>
				- Mesej untuk dibalas merupakan mesej yang akan digunakan untuk membalas komen yang mempunyai nombor telefon.
				Sila biarkan kosong sekiranya anda tidak ingin gunakan fungsi ini
				<br/>
				- Pre-fill mesej whatsapp merupakan mesej yang akan tersedia apabila anda klik link untuk whatsapp nombor telefon tersebut.
				Sila biarkan kosong sekiranya anda tidak ingin gunakan fungsi ini
			</StyledAlert>
			<TextField
				fullWidth
				multiline
				label="Mesej untuk dibalas"
				variant="outlined"
				margin="normal"
				rows={4}
				name={'message'}
				value={configData.message}
				onChange={handleChange}
				InputLabelProps={{ shrink: true }}/>
			<TextField
				fullWidth
				multiline
				label="Pre-fill mesej whatsapp"
				variant="outlined"
				margin="normal"
				rows={4}
				name={'whatsappMsg'}
				value={configData.whatsappMsg}
				onChange={handleChange}
				InputLabelProps={{ shrink: true }}/>
		</>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InnerTabMessage)