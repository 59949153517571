import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Switch from '@mui/material/Switch'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};    
  `}
`

export default function Tab3(props) {
	const {formObj, setFormObj} = props

	function addLabel(e) {
		const {value} = e.target

		const formObjClone = {...formObj}

		formObjClone.label = value.replace(/\s/g, '_').replace(/[^_A-Za-z0-9]/g, '')
		setFormObj(formObjClone)
	}

	// function setLike(e) {
	// 	const formObjClone = {...formObj}
	//
	// 	formObjClone.like = e.target.checked
	// 	setFormObj(formObjClone)
	// }

	return (
		<Box>
			<Alert severity={'info'}>
				Beberapa tetapan tambahan yang anda boleh masukkan. <br/>
				<strong>Label</strong> adalah label yang anda ingin letak pada tetapan ini supaya memudahkan
				rujukan anda semula nanti
			</Alert>

			<StyledDiv>
				<TextField
					fullWidth
					variant="outlined"
					className="label-field"
					label="Label"
					margin="normal"
					helperText="Label untuk tetapan auto reply ini"
					InputLabelProps={{shrink: true}}
					value={formObj.label}
					onChange={addLabel}
				/>

				{/*<FormControlLabel*/}
				{/*	control={<Switch checked={formObj.like} onChange={setLike} name="like" color="primary"/>}*/}
				{/*	label="Auto like komen yang diberikan"*/}
				{/*/>*/}
			</StyledDiv>
		</Box>
	)
}
