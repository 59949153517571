import requester from './requester'

export const getFacebookPagesFromFB = () => {
  return requester.get('/configuration/facebook-pages')
}

export const getInstagramFromFB = () => {
  return requester.get('/configuration/instagram')
}

export const addPage = form => {
  return requester.post('/configuration/facebook-pages', form)
}

export const swapPages = pages => {
  return requester.put('/configuration/facebook-pages', { pages })
}

export const addIg = form => {
  return requester.post('/configuration/instagram', form)
}

export const deleteIgs = igs => {
  return requester.delete('/configuration/instagram', { data: igs })
}
