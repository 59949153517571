import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

import Heading from './heading'
import CodeCopyDialog from '../../components/code-copy-dialog'
import ViewFBPost from '../../components/view-fb-posts'
import {deleteIgnorePosts} from '../../../actions/facebook'
import {SET_MISC} from '../../../constant'



const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const Tab3 = function Tab3(props) {

	const {activePage, ignores, setLoading, deleteSingle, deleteAll, setSnackbarMsg} = props

	const [showCode, setShowCode] = useState(false)
	const [postIdToView, setPostIdToView] = useState(false)

	function deletion (id = null) {

		setLoading(true)
		const promises = id ? deleteSingle(id) : deleteAll()
		promises
			.then(() => setSnackbarMsg('FB Post yang diabai berjaya dipadam'))
			.catch(() => {})
			.then(() => setLoading(false))

	}

	function viewAtFB (e, id) {
		e.preventDefault()
		e.stopPropagation()

		window.open(`https://facebook.com/${id}`, '_blank')
	}

	return (
		<Box>

			<Heading deleteAll={() => deletion()} showCode={() => setShowCode(true)} />

			{
				_.isEmpty(ignores) ? (
					<StyledBox>
						<Alert severity={'success'}>
							Tiada Facebook Post yang diabaikan oleh Bizsaya
						</Alert>
					</StyledBox>
				) : (
					<>
						<StyledBox>
							<Typography variant='body'>Senarai FB Post</Typography>
						</StyledBox>
						<List dense>
							{
								ignores.map(ignore => {
									return (
										<ListItem key={ignore.postId} divider button onClick={() => setPostIdToView(ignore.postId)}>
											<ListItemIcon>
												<IconButton onClick={e => viewAtFB(e, ignore.postId)}>
													<LaunchOutlinedIcon />
												</IconButton>
											</ListItemIcon>
											<ListItemText primary={ignore.postId} primaryTypographyProps={{ variant: 'body2' }} />
											<ListItemSecondaryAction>
												<IconButton onClick={() => deletion(ignore.id)}>
													<DeleteForeverOutlinedIcon color='warning' />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									)
								})
							}
						</List>
					</>
				)
			}

			<CodeCopyDialog
				open={showCode}
				onClose={() => setShowCode(false)}
				title='diabaikan'
				alertText='diabaikan'
				code='Bizsaya abai'
			/>

			{
				postIdToView && (
					<ViewFBPost
						pageFbId={activePage.fbId}
						postId={postIdToView}
						onClose={() => setPostIdToView(null)}
					/>
				)
			}

		</Box>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.facebook
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		deleteSingle: (id) => {
			return dispatch(deleteIgnorePosts(id))
		},
		deleteAll: () => {
			return dispatch(deleteIgnorePosts())
		},
		setLoading: (showLoading = false) => {
			dispatch({ type: SET_MISC, payload: { showLoading } })
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tab3)