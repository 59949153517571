import React, {useState} from 'react'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

import ViewFBPost from '../../components/view-fb-posts'
import CodeCopyDialog from '../../components/code-copy-dialog'


const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    .btn-container {
    	display: flex;
    	justify-content: flex-end;
    }
    
    .alert-nothing {
    	margin-top: ${theme.spacing(2)};
    }
  `}
`

export default function SubTab5(props) {

	const {formObj, setFormObj, activePage} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [showCode, setShowCode] = useState(false)
	const [postIdToView, setPostIdToView] = useState(null)

	function viewAtFB (e, id) {
		e.preventDefault()
		e.stopPropagation()

		window.open(`https://facebook.com/${id}`, '_blank')
	}

	function remove (id) {
		const formObjClone = {...formObj}
		const toBeRemoveClone = JSON.parse(JSON.stringify(formObjClone.toDeleteSpecific))
		const index = toBeRemoveClone.indexOf(id)
		if (index === -1) {
			toBeRemoveClone.push(id)
		} else {
			toBeRemoveClone.splice(index, 1)
		}

		formObjClone.toDeleteSpecific = toBeRemoveClone

		setFormObj(formObjClone)
	}
	
	return (
		<Box>
			{
				_.isNull(formObj.id) ? (
					<Alert severity={'warning'}>
						Ini adalah tetapan auto reply yang baru. Sila simpan terlebih dahulu untuk lakukan tetapan auto reply spesifik
					</Alert>
				) : (
					<Alert severity={'info'}>
						Bahagian tetapan ini digunakan untuk menetapkan auto reply ini untuk balas pada FB Post yang tertentu sahaja.
						Salin kod arahan dibawah kemudian tampal kod arahan tersebut pada mana-mana FB Post yang anda ingin dibalas mengunakkan tetapan ini.
						Sekiranya anda ingin Bizsaya reply pada mana-mana FB Post anda, Sila biarkan bahagian ini kosong.
					</Alert>
				)
			}

			{
				!_.isNull(formObj.id) && (
					<StyledDiv>
						<Grid container spacing={1} alignItems={'center'}>
							<Grid item xs={isMobile? 12 : true}>
								<Typography variant='body'>Senarai FB Post</Typography>
							</Grid>
							<Grid item xs={isMobile? 12 : true} className={'btn-container'}>
								<Button fullWidth={isMobile} variant='outlined' onClick={() => setShowCode(true)}>
									Tambah FB Post
								</Button>
							</Grid>
						</Grid>

						{
							_.isEmpty(formObj.specificId) && (
								<Alert severity={'success'} className={'alert-nothing'}>
									Tetapan auto reply ini akan membalas pada semua Facebook Posts page anda
								</Alert>
							)
						}

						<CodeCopyDialog
							open={showCode}
							onClose={() => setShowCode(false)}
							title='tetapan ini'
							alertText='tetapan auto reply'
							code={`Bizsaya set ${activePage.fbId} ${formObj.label}`}
						/>

						{
							postIdToView && (
								<ViewFBPost
									pageFbId={activePage.fbId}
									postId={postIdToView}
									onClose={() => setPostIdToView(null)}
								/>
							)
						}
					</StyledDiv>
				)
			}

			{
				!_.isNull(formObj.id) && !_.isEmpty(formObj.specificId) && (
					<>
						<List dense>
							{
								formObj.specificId.map(id => {
									return (
										<ListItem key={id} divider button onClick={() => setPostIdToView(id)}>
											<ListItemIcon>
												<IconButton onClick={e => viewAtFB(e, id)}>
													<LaunchOutlinedIcon />
												</IconButton>
											</ListItemIcon>
											<ListItemText
												primary={id}
												primaryTypographyProps={{
													variant: 'body2',
													className: formObj.toDeleteSpecific.indexOf(id) !== -1 ? 'to-be-remove' : ''
												}}
											/>
											<ListItemSecondaryAction>
												<IconButton onClick={() => remove(id)}>
													{
														formObj.toDeleteSpecific.indexOf(id) !== -1 ? (
															<CancelOutlinedIcon />
														) : (
															<DeleteForeverOutlinedIcon color='warning' />
														)
													}
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									)
								})
							}
						</List>
					</>
				)
			}
		</Box>
	)
}
