import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

import TabHeading from '../components/tab-heading'
import ListMode from './list-mode'
import FormMode from './form'
import DeleteConfirmationDialog from './delete-confirmation-dialog'

import {SET_MISC} from '../../constant'
import {createNewWhatsappLink, updateWhatsappLink, deleteWhatsappLink} from '../../actions/prospects'

const StyledBox = styled(Box)`
  ${({theme}) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `}
`

const Whatsapp = function Whatsapp(props) {

	const {
		prospects: {whatsappLinks},
		setLoading,
		setSnackbarMsg,
		createNewWhatsappLink,
		updateWhatsappLink,
		deleteWhatsappLink
	} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [listMode, setListMode] = useState(true)
	const [formObj, setFormObj] = useState({
		label: '',
		message: '',
		url: '',
		embedImg: 'https://static.bizsaya.com/file/bizsaya-public/whatsapp_logo.png',
		embedTitle: 'Klik untuk whatsapp kami',
		embedDescription: 'Klik link ini untuk whatsapp kami tanpa perlu menambah nombor kami ke contact anda',
		tId: null,
		agents: []
	})
	const [error, setError] = useState(null)
	const [code, setCode] = useState('')
	const [toBeDelete, setToBeDelete] = useState(null)
	
	useEffect(() => {
		
		if (!_.isEmpty(formObj) && _.has(formObj, 'id') && _.isNumber(formObj.id)) {
			const currentlyForm = _.find(whatsappLinks, {id: formObj.id})
			if (!_.isEmpty(_.trim(currentlyForm.tId))) {
				const formObjectClone = {...formObj}
				formObjectClone.tId = currentlyForm.tId
				setFormObj(formObjectClone)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [whatsappLinks])

	function addNew() {
		const formObjectClone = {...formObj}
		formObjectClone.label = `label${whatsappLinks.length + 1}`

		setFormObj(formObjectClone)

		setListMode(false)
	}

	function save() {

		if (_.isEmpty(_.trim(formObj.label))) {
			return setError(1)
		}
		if (_.isEmpty(formObj.agents)) {
			return setError(2)
		}

		const isUpdate = _.has(formObj, 'id') && _.isNumber(formObj.id)
		const promises = isUpdate ? updateWhatsappLink : createNewWhatsappLink

		setLoading(true)
		promises(formObj)
			.then(() => {
				const msg = isUpdate ? 'Pautan whatsapp telah dikemaskini' : 'Pautan whatsapp baru berjaya ditambah'
				setSnackbarMsg(msg)
				close()
			})
			.catch(err => console.error(err.toString()))
			.then(() => setLoading(false))
	}

	function removeTid () {
		const formObjectClone = {...formObj}
		formObjectClone.tId = null

		setLoading(true)
		updateWhatsappLink(formObjectClone)
			.then(() => {
				setSnackbarMsg('Sambungan notifikasi admin telah dibatalkan')
				setFormObj(formObjectClone)
			})
			.catch(err => console.error(err.toString()))
			.then(() => setLoading(false))
	}

	function close() {
		setFormObj({
			label: '',
			message: '',
			url: '',
			embedImg: 'https://static.bizsaya.com/file/bizsaya-public/whatsapp_logo.png',
			embedTitle: 'Klik untuk whatsapp kami',
			embedDescription: 'Klik link ini untuk whatsapp kami tanpa perlu menambah nombor kami ke contact anda',
			tId: null,
			agents: []
		})
		setListMode(true)
		setCode('')
	}

	function confirmDelete() {
		setLoading(true)
		deleteWhatsappLink(toBeDelete.id)
			.then(() => {
				setSnackbarMsg('Pautan whatsapp tersebut berjaya dipadam')
				setToBeDelete(null)
			})
			.catch(err => console.error(err.toString()))
			.then(() => setLoading(false))
	}

	return (
		<StyledBox>
			<TabHeading
				title="Tetapan Whatsapp"
				description="Cipta pautan whatapp untuk prospek anda whatsapp anda dengan mudah"
				titleProps={{sm: 12, md: 7}}
				btnContainerProps={{sm: 12, md: 5}}>
				{
					listMode ? (
						<Button
							color="primary"
							variant="outlined"
							startIcon={<AddCircleOutlineOutlinedIcon/>}
							onClick={addNew}>
							Tambah Pautan
						</Button>
					) : (
						<ButtonGroup
							orientation={isMobile ? 'vertical' : 'horizontal'}
							variant="outlined"
							color={'primary'}
							fullWidth={isMobile}>
							<Button color={'warning'} startIcon={<CancelOutlinedIcon/>} onClick={close}>
								Batal
							</Button>
							<Button startIcon={<SaveOutlinedIcon/>} onClick={save}>
								Simpan
							</Button>
						</ButtonGroup>
					)
				}

			</TabHeading>

			{
				listMode ? (
					<ListMode formObj={formObj} setFormObj={setFormObj} setListMode={setListMode} setCode={setCode}
										setToBeDelete={setToBeDelete}/>
				) : (
					<FormMode formObj={formObj} setFormObj={setFormObj} error={error} setError={setError} code={code} removeTid={removeTid}/>
				)
			}

			{
				!_.isNull(toBeDelete) && (
					<DeleteConfirmationDialog onClose={() => setToBeDelete(null)} confirm={confirmDelete}/>
				)
			}

		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		prospects: state.prospects
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({type: SET_MISC, payload: {snackbarMessage}})
		},
		createNewWhatsappLink: formObj => {
			return dispatch(createNewWhatsappLink(formObj))
		},
		updateWhatsappLink: formObj => {
			return dispatch(updateWhatsappLink(formObj))
		},
		deleteWhatsappLink: id => {
			return dispatch(deleteWhatsappLink(id))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Whatsapp)