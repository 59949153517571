import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { StyledEngineProvider, createTheme, ThemeProvider, styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import NoSsr from '@mui/material/NoSsr'

import Box from '@mui/material/Box'

import store from './store'

import Bootstrap from './bootstrap'
import Content from './content'

const customTheme = createTheme()

const StyledDiv = styled(Box)`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export default function App () {
  return (
    <Provider store={store}>
      <Router>
        <NoSsr>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTheme}>
              <StyledDiv>
                <Bootstrap />
                <CssBaseline />
                <Content />
              </StyledDiv>
            </ThemeProvider>
          </StyledEngineProvider>
        </NoSsr>
      </Router>
    </Provider>
  )
}
