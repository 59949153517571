import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined'

import TabHeading from '../../../components/tab-heading'

const Heading = function Heading(props) {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const {ignores, deleteAll, showCode} = props
	
	return (
		<TabHeading
			className="heading"
			title={'FB Post yang diabai'}
			description={'FB Post dibawah telah di-abaikan. FB Post tersebut tidak akan diproses oleh Bizsaya'}
			titleProps={{ sm: 12, md: 4, lg: 6 }}
			btnContainerProps={{ sm: 12, md: 8, lg: 6 }}>
			<ButtonGroup orientation={isMobile? 'vertical' : 'horizontal'} fullWidth={isMobile}>
				<Button
					color='error'
					variant='outlined'
					disabled={_.isEmpty(ignores)}
					startIcon={<DeleteSweepOutlinedIcon />}
					onClick={deleteAll}
				>
					Padam semua
				</Button>
				<Button
					sx={{minWidth: 200}}
					color='primary'
					variant='outlined'
					startIcon={<AddCircleOutlineOutlinedIcon />}
					onClick={showCode}
				>
					Tambah Post
				</Button>
			</ButtonGroup>
		</TabHeading>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.facebook
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Heading)