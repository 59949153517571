import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Alert from '@mui/material/Alert'

import InnerTabConfig from './inner-tab-config'
import InnerTabMessage from './inner-tab-message'
import InnerTabNotification from './inner-tab-notification'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: 0px ${theme.spacing(2)};
  `}
`

const FormControlStyled = styled(FormControl)`
  ${({theme}) => `
		margin-top: ${theme.spacing(1)};		
	`}
`
const TabBoxStyled = styled(Box)`
  ${({theme}) => `
		padding-top: ${theme.spacing(2)};
		border-bottom: 1px dotted ${theme.palette.divider};
	`}
`

const StyledBoxTab = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const FacebookPages = function FacebookPages(props) {

	const {account, prospects, formObj, setFormObj, save} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [tabIndex, setTabIndex] = useState(0)

	function changePageSelection (e) {
		const {value} = e.target
		const formObjClone = {...formObj}
		formObjClone.pageId = value

		setFormObj(formObjClone)
	}

	return (
		<Box>
			{
				!_.isEmpty(_.filter(account.FacebookPages, {isActive: true})) ? (
					<>
						<StyledBox>

							<FormControlStyled fullWidth>
								<Select
									displayEmpty
									value={formObj.pageId}
									onChange={changePageSelection}
									size={'small'}
								>
									<MenuItem value={0} disabled={prospects.configuration.length === 1}>Semua Facebook Page</MenuItem>
									{
										_.filter(account.FacebookPages, {isConnected: true}).map(fbPage => {
											return (
												<MenuItem key={fbPage.id} value={fbPage.id}>{fbPage.name}</MenuItem>
											)
										})
									}
								</Select>
								<FormHelperText>
									Sila pilih Facebook Page yang ingin ditetapkan tetapan prospek atau pilih semua Facebook Page untuk ditetapkan
								</FormHelperText>
							</FormControlStyled>
						</StyledBox>

						<TabBoxStyled>
							<Tabs
								value={tabIndex}
								onChange={(e, v) => setTabIndex(v)}
								indicatorColor="primary"
								textColor="primary"
								variant={isMobile ? 'scrollable' : 'fullWidth'}
								aria-label="full width tabs example">
								<Tab label="Tetapan"/>
								<Tab label="Notifikasi"/>
								<Tab label="Mesej"/>
							</Tabs>
						</TabBoxStyled>
						<StyledBoxTab>
							{
								tabIndex === 0 && <InnerTabConfig formObj={formObj} setFormObj={setFormObj}/>
							}
							{
								tabIndex === 1 && (
									<InnerTabNotification formObj={formObj} save={save} />
								)
							}
							{
								tabIndex === 2 && <InnerTabMessage formObj={formObj} setFormObj={setFormObj}/>
							}
						</StyledBoxTab>
					</>
				) : (
					<Alert severity={'warning'}>
						Tiada Facebook Pages yang aktif
					</Alert>
				)
			}
		</Box>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account.detail,
		prospects: state.prospects
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FacebookPages)