import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

export default function Tab2(props) {

	const {formObj, setFormObj} = props

	function onChangeMessage(e) {
		const {name, value} = e.target

		const formObjClone = {...formObj}
		formObjClone[name] = value
		setFormObj(formObjClone)
	}

	return (
		<Box>

			<Alert severity={'info'}>
				Sila masukkan mesej yang akan dibalas ke komen oleh pemberi komen.
				Gunakan kod <code>@sendername</code> untuk mention nama prospek didalam mesej yang akan diberikan.
				Kod ini boleh digunakan pada mana-mana mesej.
				<strong>
					&nbsp;Untuk makluman. Instagram tidak support Link generation dan tidak boleh reply ke direct message
					pemberi komen lagi.
				</strong>
				<i>
					&nbsp;Sekiranya pemberi komen tidak membenarkan akaun IG mereka di&quot;mention&quot;. Bizsaya akan mengantikan <code>@sendername</code>
					dengan tuan/puan/cik
				</i>
			</Alert>

			<StyledDiv>
				<TextField
					fullWidth
					multiline
					label="Mesej ke komen"
					variant="outlined"
					margin="normal"
					rows={4}
					InputLabelProps={{shrink: true}}
					name={'messageComment'}
					value={formObj.messageComment}
					onChange={onChangeMessage}
				/>

				<TextField
					fullWidth
					multiline
					label="Mesej ke inbox"
					variant="outlined"
					margin="normal"
					rows={4}
					InputLabelProps={{shrink: true}}
					name={'messageInbox'}
					value={formObj.messageInbox}
					helperText={'Hanya mesej ke komen sahaja yang boleh gunakan mention'}
					onChange={onChangeMessage}
				/>
			</StyledDiv>
		</Box>
	)
}
