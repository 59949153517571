import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'

import {SET_MISC} from '../../constant'
import useMediaQuery from '@mui/material/useMediaQuery'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    .cursor {
    	cursor: pointer;
    }
  `}
`

const ChipsBox = styled(Box)`
  ${({theme}) => `
  	display: flex;
  	flex-wrap: wrap;
  	justify-content: flex-start;
  	align-items: center;
  	padding-top: ${theme.spacing(1)};
  	margin: 0px -${theme.spacing(0.5)};
  	
  	> div {
			margin: ${theme.spacing(0.5)};
  	}
  `}
`

const BtnContainer = styled(Box)`
  ${({theme}) => `
  	display: flex;
  	justify-content: flex-end;
    padding: ${theme.spacing(1)};
  `}
`

const ListMode = function ListMode(props) {
	
	const {fetched, whatsappLinks, setSnackbarMsg, formObj, setFormObj, setListMode, setCode, setToBeDelete} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	function editThis (ws) {
		const code = `/Bizsaya set ws${ws.id} ${ws.url}`
		const formObjects = _.pick(_.cloneDeep(ws), _.keys(formObj))
		formObjects.id = ws.id

		setCode(code)
		setFormObj(formObjects)
		setListMode(false)
	}

	return (
		<StyledBox>
			{
				fetched ? (
					<>
						{
							_.isEmpty(whatsappLinks) ? (
								<Alert severity={'info'}>
									Tiada pautan whatsapp lagi
								</Alert>
							) : (
								<Grid container spacing={2}>
									{
										whatsappLinks.map(ws => {
											return (
												<Grid item key={ws.id} xs={12} sm={6} md={4}>
													<Card variant="outlined">
														<CardContent>
															<Typography variant={'h6'}>{ws.label}</Typography>
															<CopyToClipboard text={`https://ws.bizsaya.com/${ws.url}`} onCopy={() => setSnackbarMsg('Pautan whatsapp telah disalin')}>
																<Tooltip title={'Salin pautan secara automatik'}>
																	<Typography className={'cursor'} variant={'caption'}>
																		https://ws.bizsaya.com/{ws.url}
																	</Typography>
																</Tooltip>
															</CopyToClipboard>
															{
																!_.isEmpty(ws.agents) && (
																	<ChipsBox>
																		{
																			ws.agents.map((agen, i) => {
																				return (
																					<Chip key={i} label={`${agen.name} +601${agen.number}`} size={'small'} variant="outlined" />
																				)
																			})
																		}
																	</ChipsBox>
																)
															}
														</CardContent>
														<Divider />
														<BtnContainer>
															<ButtonGroup orientation={isMobile? 'vertical' : 'horizontal'} fullWidth={isMobile} variant={'outlined'}>
																<Button color={'error'} onClick={() => setToBeDelete(ws)}>Padam</Button>
																<Button onClick={() => editThis(ws)}>Kemaskini</Button>
															</ButtonGroup>
														</BtnContainer>
													</Card>
												</Grid>
											)
										})
									}
								</Grid>
							)
						}
					</>
				) : (
					<Alert severity={'info'}>
						Mendapatkan maklumat tetapan pautan whatsapp. Sila tunggu sebentar
					</Alert>
				)
			}
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {
		fetched: state.prospects.fetched,
		whatsappLinks: state.prospects.whatsappLinks
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({ type: SET_MISC, payload: { snackbarMessage } })
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListMode)