import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Link} from 'react-router-dom'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import Charts from './blocks/chart'
import PageListing from './blocks/page-listing'
import NewUser from './blocks/new-user'

import {getHomepageDetail, getStats} from '../../actions/homepage'

const StyleAlert = styled(Alert)`
  ${({theme}) => `
    margin-bottom: ${theme.spacing(2)};
    
    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
      font-weight: 700;
    }
  `}
`

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    .next-grid {
      margin-top: ${theme.spacing(2)};
    }
  `}
`

const Homepage = function Homepage(props) {

	const {homepage, account: {detail: account}, getHomepageDetail, getStats, history} = props
	const {FacebookPages: fbPages} = account

	const isEmpty = _.isEmpty(homepage)

	useEffect(() => {
		if (isEmpty) {
			getHomepageDetail({})
				.then(() => {
				})
				.catch(err => console.error(err))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, isEmpty])

	return (
		<>

			{
				!account.isTrial && (
					<StyleAlert severity={'info'}>
						Sudahkan anda join group support Whatsapp Bizsaya?
						Marilah <a href="https://chat.whatsapp.com/FSv2lMDEcL08Rut2vbbyJK" target="_blank" rel="noreferrer">join
						group</a> tersebut
						untuk memudahkan anda mendapatkan berita urgent berkenaan Bizsaya. Strictly no ads.
					</StyleAlert>
				)
			}

			<StyledBox>
				{
					!_.isEmpty(_.filter(fbPages, {isEnable: true})) && (
						<Grid container spacing={2}>
							{
								!_.isEmpty(_.filter(fbPages, {isActive: true})) && (
									<Grid item xs={12}>
										<Charts account={account} pagesData={homepage.pages} getStats={getStats}/>
									</Grid>
								)
							}
							<Grid item md={4} xs={12}>
								<PageListing history={history} fbPages={fbPages} />
							</Grid>
						</Grid>
					)
				}
				{
					_.isEmpty(_.filter(fbPages, {isEnable: true})) && !_.isEmpty(fbPages) && (
						<StyleAlert severity={'warning'}>
							<AlertTitle>Tiada Facebook Pages yang aktif</AlertTitle>
							Tiada Facebook Pages yang aktif dan boleh dibenarkan akses di Bizsaya. Sila buat tetapan Facebook Page anda di <Link to={'/config?tab=2'}>modul tetapan tab Facebook Pages</Link>
						</StyleAlert>
					)
				}
				{
					_.isEmpty(fbPages) && (
						<NewUser />
					)
				}
			</StyledBox>
		</>
	)
}

const mapStateToProps = (state) => {
	return {...state}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getStats,
		getHomepageDetail: params => dispatch(getHomepageDetail(params))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Homepage)