import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import Cookie from 'js-cookie'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import Form from './form'
import Listing from './lists-auto-replies'
import {SET_ACTIVE_IG, SET_MISC} from '../../constant'
import {createAutoReply, deleteAutoReply, getInstaDetail, updateAutoReply} from '../../actions/instagram'

import Heading from './heading'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `}
`

const AlertStyled = styled(Alert)`
  ${({theme}) => `
    margin: ${theme.spacing(2)};
    
    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
      font-weight: 700;
    }
  `}
`

const Instagram = function Instagram(props) {

	const {
		account,
		activeIg,
		getInstaDetail,
		autoReplies,
		createAutoReply,
		updateAutoReply,
		deleteAutoReply,
		setLoading,
		setSnackbarMsg
	} = props

	const _DEFAULTS = {
		id: null,
		label: '',
		keywords: ['pm', 'nak', 'nk', 'harga', 'harge', 'hrga', 'hrg', 'price', 'berape', 'berapa', 'brape', 'brp', 'brapa'],
		messageComment: '',
		messageInbox: '',
		like: true
	}

	const [openForm, setOpenForm] = useState(false)
	const [formObj, setFormObj] = useState({..._DEFAULTS})
	const [errCode, setErrCode] = useState(null)

	useEffect(() => {

		if (!openForm) {
			setFormObj({..._DEFAULTS})
			setErrCode(null)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openForm])
	
	useEffect(() => {

		if (!_.isUndefined(activeIg.id)) {
			getInstaDetail()
			setOpenForm(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIg.id])

	function createNew() {
		const forms = {..._DEFAULTS}
		forms.label = `autoReply${autoReplies.length + 1}`
		setFormObj(forms)
		setOpenForm(true)
	}

	function save() {

		setErrCode(null)
		const promises = _.isNull(formObj.id) ? createAutoReply : updateAutoReply

		if (_.isEmpty(formObj.keywords)) {
			return setErrCode(1)
		}

		if (_.isEmpty(_.trim(formObj.label))) {
			formObj.label = `autoReply${autoReplies.length + 1}`
		}

		setLoading(true)
		promises(formObj)
			.then(() => {
				setSnackbarMsg('Tetapan auto reply berjaya disimpan')
				setOpenForm(false)
			})
			.catch(err => console.log(err))
			.then(() => setLoading(false))
	}

	function editThis(autoReply) {
		const form = _.pick({...autoReply}, _.keys({..._DEFAULTS}))
		form.keywords = form.keywords.split(',')

		setFormObj(form)
		setOpenForm(true)
	}

	function confirmDeletion(id) {
		setLoading(true)
		deleteAutoReply(id)
			.then(() => setSnackbarMsg('Tetapan auto reply berjaya dipadam'))
			.catch(err => console.log(err))
			.then(() => setLoading(false))
	}

	return (
		<StyledDiv>
			{
				!_.isEmpty(_.filter(account.InstagramInsta, {isActive: true})) ? (
					<>
						<Heading openForm={openForm} setOpenForm={setOpenForm} createNew={createNew} save={save}/>

						{
							openForm ? (
								<Form formObj={formObj} setFormObj={setFormObj} errCode={errCode} setErrCode={setErrCode}/>
							) : (
								<Listing editThis={editThis} confirmDeletion={confirmDeletion}/>
							)
						}
					</>
				) : (
					<AlertStyled severity={'warning'}>
						<AlertTitle>Tiada Instagram yang aktif</AlertTitle>
						Tiada Instagram yang aktif untuk auto reply.
						Sila ke <Link to={'/config?tab=3'}>modul tetapan tab Instagram</Link> untuk buat tetapan instagram
					</AlertStyled>
				)
			}
		</StyledDiv>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.instagram,
		account: state.account.detail
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		activeThisIg: toBeActivePage => {
			Cookie.set('actig', toBeActivePage.id)
			dispatch({type: SET_ACTIVE_IG, payload: toBeActivePage})
		},
		getInstaDetail: () => {
			dispatch(getInstaDetail())
		},
		createAutoReply: form => {
			return dispatch(createAutoReply(form))
		},
		updateAutoReply: form => {
			return dispatch(updateAutoReply(form))
		},
		deleteAutoReply: id => {
			return dispatch(deleteAutoReply(id))
		},
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		setSnackbarMsg: (snackbarMessage = null) => {
			dispatch({type: SET_MISC, payload: {snackbarMessage}})
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Instagram)
