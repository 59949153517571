import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Alert from '@mui/material/Alert'

import CodeCopyDialog from '../../components/code-copy-dialog'
import ViewFBPost from '../../components/view-fb-posts'

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const StyledSecondBox = styled(Box)`
	${({theme}) => `
		border-top: 1px dotted ${theme.palette.divider};
		padding: 0px ${theme.spacing(2)};
	`}
`

const Specifics = function Specifics(props) {

	const {activePage, formObj, setFormObj} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [showCode, setShowCode] = useState(false)
	const [postIdToView, setPostIdToView] = useState(null)

	function changeFormSpecific (e) {
		const {name, checked, value} = e.target
		
		const formObjClone = {...formObj}

		if (name === 'tagToSpecific') {
			formObjClone[name] = value === '1'
		} else {
			formObjClone[name] = checked	
		}

		setFormObj(formObjClone)
	}

	function viewAtFB (e, id) {
		e.preventDefault()
		e.stopPropagation()

		window.open(`https://facebook.com/${id}`, '_blank')
	}

	function remove (id) {
		const formObjClone = {...formObj}
		const toBeRemoveClone = JSON.parse(JSON.stringify(formObjClone.toBeRemove))
		const index = toBeRemoveClone.indexOf(id)
		if (index === -1) {
			toBeRemoveClone.push(id)
		} else {
			toBeRemoveClone.splice(index, 1)
		}

		formObjClone.toBeRemove = toBeRemoveClone

		setFormObj(formObjClone)
	}

	return (
		<Box>
			<Alert severity={'info'}>
				Sekiranya anda ingin tetapan auto reply tag ini reply pada semua
				Facebook Post anda ataupun pada Facebook Post tertentu ataupun pada semua
				Facebook Post kecuali yang pada Facebook Post tertentu.
			</Alert>

			<StyledBox>
				<FormGroup>
					<FormControlLabel
						className='checboxline'
						control={(
							<Switch
								checked={formObj.tagToAll}
								onChange={changeFormSpecific}
								name='tagToAll'
								color='primary'
							/>
						)}
						label='Auto reply mesej tag pada semua FB Post'
					/>
				</FormGroup>

			</StyledBox>

			{
				!formObj.tagToAll && (
					<StyledSecondBox>
						<Grid container spacing={1} mt={2}>
							<Grid item xs={isMobile? 12 : true}>
								<FormControl component='fieldset'>
									<RadioGroup
										name='tagToSpecific'
										value={formObj.tagToSpecific ? '1' : '0'}
										onChange={changeFormSpecific}
									>
										<FormControlLabel
											value='1'
											control={<Radio color='primary' />}
											label='Auto reply pada FB post dibawah'
										/>
										<FormControlLabel
											value='0'
											control={<Radio color='primary' />}
											label='Auto reply pada SELAIN FB post dibawah'
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={isMobile? 12 : true} sx={{display: 'flex'}} justifyContent={'flex-end'} alignItems={'center'}>
								<Button fullWidth={isMobile} variant='outlined' onClick={() => setShowCode(true)}>
									Tambah FB Post
								</Button>
							</Grid>
						</Grid>
					</StyledSecondBox>
				)
			}

			<StyledBox>
				<Typography variant='body'>Senarai FB Post</Typography>
			</StyledBox>

			{
				!_.isEmpty(formObj.specificId) && !formObj.tagToAll && (
					<List dense>
						{
							formObj.specificId.map(id => {
								return (
									<ListItem key={id} divider button onClick={() => setPostIdToView(id)}>
										<ListItemIcon>
											<IconButton onClick={e => viewAtFB(e, id)}>
												<LaunchOutlinedIcon />
											</IconButton>
										</ListItemIcon>
										<ListItemText
											primary={id}
											primaryTypographyProps={{
												variant: 'body2',
												className: formObj.toBeRemove.indexOf(id) !== -1 ? 'to-be-remove' : ''
											}}
										/>
										<ListItemSecondaryAction>
											<IconButton onClick={() => remove(id)}>
												{
													formObj.toBeRemove.indexOf(id) !== -1 ? (
														<CancelOutlinedIcon />
													) : (
														<DeleteForeverOutlinedIcon color='warning' />
													)
												}
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)
							})
						}
					</List>
				)
			}

			<CodeCopyDialog
				open={showCode}
				onClose={() => setShowCode(false)}
				title='auto reply Tag'
				alertText='auto reply tag'
				code='Bizsaya tag' />

			{
				postIdToView && (
					<ViewFBPost
						pageFbId={activePage.fbId}
						postId={postIdToView}
						onClose={() => setPostIdToView(null)}
					/>
				)
			}
		</Box>
	)
}

const mapStateToProps = (state) => {
	return {
		...state.facebook
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Specifics)