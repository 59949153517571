import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Alert from '@mui/material/Alert'

const InnerTabConfig = function InnerTabConfig(props) {

	const {confData, setConfData, activePage} = props

	const configData = _.find(confData, {pageId: activePage}) || {}

	function handleChange(e, v) {
		const configDataIndex = _.findIndex(confData, {pageId: activePage})

		if (configDataIndex !== -1) {
			const confDataClone = JSON.parse(JSON.stringify(confData))
			confDataClone[configDataIndex][e.target.name] = v
			setConfData(confDataClone)
		}
	}

	if (_.isEmpty(configData)) {
		return (
			<Alert severity={'info'}>
				Fungsi tetapan prospek bagi instagram masih belum tersedia
			</Alert>
		)
	}

	return (
		<>
			<FormControl component="fieldset" variant="standard">
				<FormGroup>
					<FormControlLabel
						control={<Switch checked={configData.fetch} onChange={handleChange} name="fetch"/>}
						label="Cari nombor telefon daripada comment dan inbox Facebook Page"
					/>
					<FormControlLabel
						control={
							<Switch checked={configData.autoHideProspect} onChange={handleChange} name="autoHideProspect"/>
						}
						label="Sembunyikan (hide) komen yang diberikan sekiranya terdapat nombor telefon"
					/>
				</FormGroup>
			</FormControl>
		</>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InnerTabConfig)