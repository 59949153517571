import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

export default function Error404 (props) {
  return (
    <StyledBox>
      <Alert severity={'error'}>
        <AlertTitle>Ralat 404</AlertTitle>
        Halaman tidak dijumpai. Sila klik pada menu utama untuk mula semula.
      </Alert>
    </StyledBox>
  )
}
