import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'

import ListItem from '@mui/material/ListItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'

export default function Listing(props) {

	const {facebookPagesFromFB, FacebookPages, cannotAddFB, toPay, setPayToPayment, setPageToSwap, setMsg} = props

	const [listing, setListing] = useState([])

	useEffect(() => {

		let ids = []
		facebookPagesFromFB.forEach(p => {
			ids.push(p.id)
		})
		FacebookPages.forEach(p => {
			ids.push(p.fbId)
		})
		
		ids = _.uniq(ids)

		const listingPages = []
		FacebookPages.forEach(p => {
			const index = ids.indexOf(p.fbId)
			if (index !== -1) {
				listingPages.push({
					id: p.fbId,
					name: p.name
				})
				ids.splice(index, 1)
			}
		})

		facebookPagesFromFB.forEach(p => {
			if (ids.indexOf(p.id) !== -1) {
				listingPages.push(p)
			}
		})

		setListing(listingPages)

	}, [facebookPagesFromFB, FacebookPages])

	function changeCheckbox (fbPage) {
		setPayToPayment(fbPage)
		setMsg('')
	}

	//

	return (
		<List>
			{
				listing.map((fbPage, i) => {

					const existingPage = _.find(FacebookPages, {fbId: fbPage.id})
					const disable = _.find(cannotAddFB, {fbId: fbPage.id})

					let secondary = 'Ini adalah Facebook Page baru'
					let color = 'secondary'
					let toSwap = false

					if (existingPage) {
						const {isActive, expiredAt} = existingPage
						if (isActive) {
							secondary = `Akaun aktif sehingga ${dayjs(expiredAt).format('DD-MM-YYYY')}. Klik sini untuk tukar/swap Facebook Page ini`
							color = 'default'
							toSwap = true
						} else {
							secondary = `Akaun telah tamat akses sejak ${dayjs(expiredAt).format('DD-MM-YYYY')}`
							color = 'warning'
						}
					}

					if (disable) {
						secondary = `Facebook page ini diuruskan oleh akaun ${disable.name}`
						color = 'error'
					}

					const checked = _.findIndex(toPay, {id: fbPage.id})
					if (checked !== -1) {
						secondary = `Facebook Page sedia untuk pembayaran`
						color = 'primary'
						toSwap = false
					}

					const element = (
						<>
							<ListItemAvatar>
								<Avatar src={`https://graph.facebook.com/v2.10/${fbPage.id}/picture`}/>
							</ListItemAvatar>
							<ListItemText
								primary={fbPage.name}
								secondary={secondary}
								secondaryTypographyProps={{color}}
							/>
						</>
					)

					return (
						<ListItem
							key={i}
							secondaryAction={
								<Checkbox
									edge="end"
									onChange={() => changeCheckbox(fbPage)}
									checked={checked !== -1}
									disabled={!_.isEmpty(disable)}/>
							}

						>
							{
								toSwap ? (
									<ListItemButton sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => setPageToSwap(existingPage)} disableGutters >{element}</ListItemButton>
								) : (
									<>{element}</>
								)
							}
						</ListItem>
					)
				})
			}
		</List>
	)
}
