import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

import Listing from './listing'
import PaymentsDialog from './payment-dialog'
import SwapPageDialog from './swap-page-dialog'
import TabHeading from '../../components/tab-heading'
import HeadingBtn from './heading-btn'
import {parse} from '../../../functions/querystring'
import {SET_MISC, SET_FB_PAGE_FROM_FB} from '../../../constant'
import {getProfile} from '../../../actions/profile'
import {getProspect} from '../../../actions/prospects'
import {getFacebookPagesFromFB} from '../../../actions/configuration'
import {swapPages} from '../../../actions/configuration'
import {getAuthLink} from '../../../actions/facebook'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const Tab2 = function Tab2(props) {

  const {
		history,
		setLoading,
		account,
		location,
		getFacebookPagesFromFB,
		saveFacebookPagesFromFB,
		swapPages,
		reFetchDetail,
		getAuthLink
	} = props

	const {detail, facebookPagesFromFBFetch, facebookPagesFromFB} = account
	const {FacebookPages} = detail
	
  const [msg, setMsg] = useState('')
  const [msgType, setMsgType] = useState('error')
	const [cannotAddFB, setCannotAddFB] = useState([])
	const [toPay, setToPay] = useState([])
	const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
	const [pageToSwap, setPageToSwap] = useState({})

  useEffect(() => {

    const query = parse(location.search.replace(/^\?/, ''))
    if (_.has(query, 'payment')) {
      setMsg(`Pembayaran secara online ${query.payment === '0'? 'gagal. Sila cuba semula' : 'berjaya. Akaun anda telah dikemaskini secara automatik'}`)
      setMsgType(query.payment === '0' ? 'error' : 'success')
			history.push('/config?tab=2')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

	useEffect(() => {

		if (!facebookPagesFromFBFetch) {
			setLoading(true)
			getFacebookPagesFromFB()
				.then(response => {
					const {fbPages, belongsToSomeoneElse} = response.data
					const cannot = []
					belongsToSomeoneElse.forEach(b => {
						cannot.push({
							fbId: b.fbId,
							name: b.Accounts.fbAppName
						})
					})
					setCannotAddFB(cannot)

					saveFacebookPagesFromFB(JSON.parse(JSON.stringify(fbPages)))
					setLoading(false)
				})
				.catch(err => {
					if (err.response) {
						const {status} = err.response
						if (status === 409) {
							setMsg('Akaun baru. Sila tunggu sebentar')
							setMsgType('warning')

							getAuthLink()
								.then(response => {
									if (!_.isEmpty(_.trim(response.data.url))) {
										window.location.href = response.data.url
									}
								})
								.catch(err => console.error(err))
						} else {
							setLoading(false)
						}
					} else {
						setLoading(false)
					}
				})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facebookPagesFromFBFetch])

	function setPayToPayment (fbPage) {
		const toPayClone = JSON.parse(JSON.stringify(toPay))

		const data = {..._.pick(fbPage, ['id', 'name'])}
		const index = _.findIndex(toPayClone, {id: fbPage.id})
		data.month = 1

		if (index === -1) {
			toPayClone.push(data)
		} else {
			toPayClone.splice(index, 1)
		}

		setToPay(toPayClone)
	}

	function swapThePage (before, after) {
		setLoading(true)
		swapPages([{ from: before.fbId, to: after.id }])
			.then(() => setPageToSwap({}))
			.then(() => reFetchDetail())
			.then(() => {
				setMsg('Facebook Page telah berjaya ditukar')
				setMsgType('success')
			})
			.catch(err => {
				setMsg('Ralat sewaktu menukar Facebook Page')
				setMsgType('error')
			})
			.then(() => setLoading(false))
	}
	
	return (
		<>
      <TabHeading
        title='Tetapan Facebook Pages'
        description={'Kemaskini, tukar dan tambah Facebook Pages'}
        titleProps={{ sm: 12, md: 5, lg: 5 }}
        btnContainerProps={{ sm: 12, md: 7, lg: 7 }}>
        <HeadingBtn
					toPay={toPay}
					setOpenPaymentDialog={setOpenPaymentDialog}
				/>
      </TabHeading>

			{
				!facebookPagesFromFBFetch && (
					<StyledBox>
						<Alert severity={'info'}>
							Sedang mendapatkan maklumat akses Facebook Pages anda
						</Alert>
					</StyledBox>
				)
			}
			{
				!_.isEmpty(_.trim(msg)) && (
					<StyledBox>
						<Alert severity={msgType} onClose={() => setMsg('')}>{msg}</Alert>
					</StyledBox>
				)
			}
			{
				facebookPagesFromFBFetch && (
					<>
						{
							(_.isEmpty(facebookPagesFromFB)) ? (
								<Alert severity={'error'}>
									Anda tidak membenarkan sebarang akses Facebook Page anda ke Bizsaya
								</Alert>
							) : (
								<Listing
									facebookPagesFromFB={facebookPagesFromFB}
									FacebookPages={FacebookPages}
									cannotAddFB={cannotAddFB}
									toPay={toPay}
									setPayToPayment={setPayToPayment}
									setPageToSwap={setPageToSwap}
									setMsg={setMsg}
								/>
							)
						}
					</>
				)
			}

			<PaymentsDialog
				open={openPaymentDialog}
				onClose={() => setOpenPaymentDialog(false)}
				toPay={toPay}
				setToPay={setToPay} />

			{
				!_.isEmpty(pageToSwap) && (
					<SwapPageDialog
						pageToSwap={pageToSwap}
						facebookPagesFromFB={facebookPagesFromFB}
						onClose={() => setPageToSwap({})}
						swapThePage={swapThePage}
					/>
				)
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		account: state.account
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		reFetchDetail: () => {
			dispatch(getProfile())
			dispatch(getProspect())
		},
		getFacebookPagesFromFB,
		saveFacebookPagesFromFB: payload => {
			dispatch({type: SET_FB_PAGE_FROM_FB, payload})
		},
		swapPages,
		getAuthLink
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Tab2))
