import React from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'

const StyleFormControl = styled(FormControl)`
  ${({theme}) => `
  	padding: ${theme.spacing(2)};
  
    &.div {
    	margin-bottom: ${theme.spacing(4)};
    }
  `}
`


export default function Tab1(props) {

	const {formObj, setFormObj, error, setError} = props
	const {label, message} = formObj

	function update (e) {
		const {name, value} = e.target
		const formObjClone = {...formObj}

		formObjClone[name] = value
		setFormObj(formObjClone)
	}

	return (
		<Box>

			<Alert severity={'info'}>
				Sila masukkan label bagi pautan whatsapp ini. Mesej boleh dibiarkan kosong.
				Gunakan <b>@agentname</b> untuk mention nama agen yang akan &quot;close deal&quot; dengan prospek
			</Alert>

			<StyleFormControl fullWidth>

				<TextField
					fullWidth
					label="Label"
					variant="outlined"
					margin="normal"
					name={'label'}
					value={label}
					error={error === 1}
					inputProps={{ maxLength: 255 }}
					helperText={'Label pautan whatsapp ini adalah diperlukan'}
					onChange={update}
					onClick={() => setError(null)}
					InputLabelProps={{ shrink: true }}/>

				<TextField
					fullWidth
					multiline
					label="Mesej dari prospek"
					variant="outlined"
					margin="normal"
					rows={4}
					name={'message'}
					value={message}
					helperText='Mesej yang akan dihantar oleh prospek ke agen anda'
					onChange={update}
					InputLabelProps={{ shrink: true }}/>

			</StyleFormControl>

		</Box>
	)
}
