import React from 'react'
import _ from 'lodash'

import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import Copy from './copy'

export default function Paper2(props) {

	const {appUserId, fbId, updateThePage, setSnackbarMsg} = props

	function onCopy () {
		setSnackbarMsg('Kod arahan bagi fungsi admin ID telah disalin secara automatik')
	}

	return (
		<Paper sx={{padding: 2}}>
			<Alert severity={'info'}>
				Salin kod arahan dibawah dan tampal ke mana-mana Facebook Post anda. Pastikan anda tampal
				ko arahan tersebut menggunakan Facebook Personal anda.
				Dengan ini, Bizsaya akan tahu yang anda adalah admin Page dan komen yang diberikan oleh anda tidak
				akan diblock. Selain itu juga. anda boleh berikan tetapan arahan menggunakan akaun personal anda.
			</Alert>

			{
				_.isEmpty(appUserId) ? (
					<Copy code={`Bizsaya set admin ${fbId}`} onCopy={onCopy} />
				) : (
					<Box mt={2}>
						<Button
							fullWidth
							variant='outlined'
							color='secondary'
							startIcon={<FileCopyOutlinedIcon />}
							onClick={() => updateThePage({ appUserId: null })}
						>
							Batalkan admin ID
						</Button>
					</Box>
				)
			}

		</Paper>
	)
}