import Cookie from 'js-cookie'
import _ from 'lodash'

import requester from './requester'
import {
  SET_FACEBOOK,
  ADD_NEW_AUTO_REPLY,
  UPDATE_AUTO_REPLY,
  DELETE_AUTO_REPLY
} from '../constant'

export const getAuthLink = (state = undefined) => {
  return requester.get('/fb/authlink', { params: { state } })
}

export const verifyCode = (code, state) => {
  return requester.post('/fb/authlink', { code, state })
}

export const confirmPage = pages => {
  return requester.post('/fb/pages', { pages })
}

export const getFacebookDetail = () => dispatch => {
  const pageId = Cookie.get('actpg')
  if (_.isEmpty(_.trim(pageId))) {
    return Promise.reject(new Error('Missing page'))
  }

  return requester.get(`/fb/${pageId}/auto-replies`)
    .then(response => {
      dispatch({ type: SET_FACEBOOK, payload: response.data })
    })
    .catch(err => {
      console.error(err)
    })
}

export const updateFacebookDetail = form => dispatch => {
  const pageId = Cookie.get('actpg')
  return requester.put(`/fb/${pageId}/auto-replies`, form)
    .then(response => {
      dispatch({ type: SET_FACEBOOK, payload: { configuration: response.data } })
    })
}

export const createAutoReply = form => dispatch => {
  const pageId = Cookie.get('actpg')
  return requester.post(`/fb/${pageId}/auto-replies`, form)
    .then(response => {
      dispatch({ type: ADD_NEW_AUTO_REPLY, payload: response.data })
    })
}

export const updateAutoReply = form => dispatch => {
  const pageId = Cookie.get('actpg')
  return requester.put(`/fb/${pageId}/auto-replies/${form.editId}`, form)
    .then(response => {
      dispatch({ type: UPDATE_AUTO_REPLY, payload: response.data })
    })
}

export const deleteAutoReply = id => dispatch => {
  const pageId = Cookie.get('actpg')
  return requester.delete(`/fb/${pageId}/auto-replies/${id}`)
    .then(() => {
      dispatch({ type: DELETE_AUTO_REPLY, payload: id })
    })
}

export const deleteIgnorePosts = (id = null) => dispatch => {
  const pageId = Cookie.get('actpg')
  const url = id ? `/fb/${pageId}/ignore/${id}` : `/fb/${pageId}/ignore`

  return requester.delete(url)
    .then(response => {
      dispatch({ type: SET_FACEBOOK, payload: { ignores: response.data } })
    })
}

export const checkUrl = url => {
  return requester.post('/fb/verifyurl', { url })
}