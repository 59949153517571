import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import {getAuthLink, verifyCode} from '../../actions/facebook'
import {getAuthLink as getAuthLinkIg, verifyCode as verifyCodeIg} from '../../actions/instagram'
import {SET_MISC} from '../../constant'
import {getProfile} from '../../actions/profile'
import {getProspect} from '../../actions/prospects'

const {parse}  = require('../../functions/querystring')

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    
    .btn-container {
      display: flex;
      justify-content: center;
      margin-top: ${theme.spacing(3)};
      position: relative;
      
      .overlay {
        position: absolute;
        z-index: 10000;
        width: 250px;
        height: 40px;
        cursor: pointer;
      }
    }
  `}
`

const StyledAlert = styled(Alert)`
	${({theme}) => `
		margin-bottom: ${theme.spacing(2)};
		
		a {
    	text-decoration: none;
			color: ${theme.palette.info.main};
    }
	`}
`

const ConfigCallback = function ConfigCallback(props) {

	const {setLoading, location, history, reFetchDetail} = props

	const [msg, setMsg] = useState('')
	const [msgType, setMsgType] = useState('info')
	const [pageNotLink, setPageNotLink] = useState([])

	const timeout = useRef()

	useEffect(() => {

		const qs = parse(location.search.replace(/^\?/, ''))

		if (!_.has(qs, 'code')) {
			setMsg('Maklumat pengesahan tidak sah. Sila cuba semula dengan klik butang dibawah')
			setMsgType('error')
			return showLoginBtn()
		}

		setLoading(true)

		if (location.pathname === '/config-ig-callback') {
			setMsg('Mengesahkan maklumat instagram. Sila tunggu sebentar')
			setMsgType('info')

			verifyCodeIg(qs.code, qs.state || 'reauth')
				.then(() => reFetchDetail())
				.then(() => setLoading(false))
				.then(() => history.push('/config?tab=3'))
				.catch(err => {
					showLoginBtn()
					setMsgType('error')
					if (err.response && !_.isEmpty(err.response.data)) {
						setMsg(err.response.data.msg)
					} else {
						setMsg('Ralat!')
					}
				})
				.then(() => setLoading(false))

		} else {
			setMsg('Mengesahkan maklumat facebook page. Sila tunggu sebentar')
			setMsgType('info')

			verifyCode(qs.code, qs.state || 'reauth')
				.then(response => {
					const {state, pageNotLinked} = response.data
					reFetchDetail()
					setLoading(false)
					setPageNotLink(JSON.parse(JSON.stringify(pageNotLinked)))

					if (!_.isEmpty(pageNotLinked)) {
						setMsg('')
						showLoginBtn()
						return null
					}

					if (state === 'reset') {
						history.push('/')
					} else {
						history.push('/config?tab=2')
					}

				})
				.catch(err => {
					showLoginBtn()
					setMsgType('error')
					if (err.response && !_.isEmpty(err.response.data)) {
						setMsg(err.response.data.msg)
					} else {
						setMsg('Ralat!')
					}
				})
				.then(() => setLoading(false))
		}

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function reAuth () {

		setLoading(true)
		const {pathname} = location
		const promise = pathname === '/config-ig-callback'? getAuthLinkIg('reauth') : getAuthLink('reauth')

		promise.then(response => {
				if (response.data && response.data.url) {
					window.location.href = response.data.url
				}
			})
			.catch(err => console.error(err))
			.then(() => setLoading(false))
	}

	function showLoginBtn () {
		timeout.current = setTimeout(() => {
			const element = document.getElementById('fb-login-container-loader-uniq')
			if (element) {
				FB.XFBML.parse(element)
			}
		}, 600)
	}

	return (
		<StyledBox>
			{
				!_.isEmpty(_.trim(msg)) && <StyledAlert severity={msgType}>{msg}</StyledAlert>
			}
			{
				!_.isEmpty(pageNotLink) && (
					<StyledAlert severity={'warning'}>
						<AlertTitle>Oh no! Terlepas Facebook Page yang sedang aktif?</AlertTitle>
						Facebook Page <i>{pageNotLink.join()}</i> tidak dibenarkan akses oleh
						Bizsaya sedangkan Facebook Page ini masih aktif sebelum ini.
						Sila benarkan akses ke Facebook Page tersebut melainkan anda ingin <Link to={'/config?tab=2'}>menukar Facebook Page</Link>.
					</StyledAlert>
				)
			}

			<Box className='btn-container' id='fb-login-container-loader-uniq'>
				<Box className='overlay' onClick={reAuth} />
				<div
					className='fb-login-button'
					data-size='large'
					data-button-type='continue_with'
					data-layout='default'
					data-auto-logout-link='false'
					data-use-continue-as='true'
					data-height='40'
					data-width='240'
				/>
			</Box>
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({ type: SET_MISC, payload: { showLoading } })
		},
		reFetchDetail: () => {
			dispatch(getProfile())
			dispatch(getProspect())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConfigCallback)