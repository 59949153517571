export default [{
	label: 'Utama',
	to: '/',
	active: false
}, {
	label: 'Facebook',
	to: '/fb',
	active: false
}, {
	label: 'Instagram',
	to: '/ig',
	active: false
}, {
	label: 'Prospek',
	to: '/prospects',
	active: false
}, {
	label: 'Whatsapp',
	to: '/whatsapp',
	active: false
},
// 	{
// 	label: 'Telegram',
// 	to: '/telegram',
// 	active: false
// },
// {
//   label: 'Order form',
//   to: '/orderform',
//   active: false,
//   icon: <OrderFormIcon />
// },
// {
//   label: 'TikTok',
//   to: '/tiktok',
//   active: false,
//   icon: <PlayCircleOutlineOutlinedIcon />
// },
// {
//   label: 'Maklumat akaun',
//   to: '/me',
//   active: false,
//   icon: <ProfileIcon />
// },
	{
		label: 'Tetapan',
		to: '/config',
		active: false
	},
	// {
	// 	label: 'Tutorial',
	// 	to: '/tutor',
	// 	active: false
	// }
	]
