import {
  SET_DEBUG
} from '../../constant'

const initialState = {
  fb: [],
  ig: []
}

export default function AccountReducer (state = initialState, action) {
  switch (action.type) {
    case SET_DEBUG: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
