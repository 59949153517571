import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

import {parse} from '../../functions/querystring'
import {checkBill} from '../../actions/payments'
import {SET_MISC} from '../../constant'
import {getProfile} from '../../actions/profile'
import {getProspect} from '../../actions/prospects'

const StyledBox = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
  `}
`

const PaymentsCallback = function PaymentsCallback(props) {

	const {location, history, checkBill, setLoading, reFetchDetail} = props

	useEffect(() => {

		const query = parse(location.search.replace(/^\?/, ''))

		setLoading(true)
		checkBill(query)
			.then(response => {
				const {success} = response.data
				if (success) {
					reFetchDetail()
				}
				history.push(`/config?tab=2&payment=${!success? '0' : '1'}`)
			})
			.catch(err => console.error(err))
			.then(() => setLoading(false))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<StyledBox>
			<Alert severity={'info'}>
				Sila tunggu sebentar untuk pengesahan pembayaran dan mengemaskini Facebook Page
			</Alert>
		</StyledBox>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		checkBill,
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		reFetchDetail: () => {
			dispatch(getProfile())
			dispatch(getProspect())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentsCallback)