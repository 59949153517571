import React from 'react'
import {connect} from 'react-redux'

import {styled} from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'


const StyledAlert = styled(Alert)`
  ${({theme}) => `
    margin-bottom: ${theme.spacing(2)};
  `}
`

const InnerTabMessage = function InnerTabMessage(props) {

	const {formObj, setFormObj} = props

	function handleChange(e) {
		const {name, value} = e.target
		const formObjClone = {...formObj}

		formObjClone[name] = value
		setFormObj(formObjClone)
	}

	return (
		<>
			<StyledAlert severity={'info'}>
				- Mesej untuk dibalas merupakan mesej yang akan digunakan untuk membalas komen yang mempunyai nombor telefon.
				Sila biarkan kosong sekiranya anda tidak ingin gunakan fungsi ini
				<br/>
				- Pre-fill mesej whatsapp merupakan mesej yang akan tersedia apabila anda klik link untuk whatsapp nombor telefon tersebut.
				Sila biarkan kosong sekiranya anda tidak ingin gunakan fungsi ini
			</StyledAlert>
			<TextField
				fullWidth
				multiline
				label="Mesej untuk dibalas"
				variant="outlined"
				margin="normal"
				rows={4}
				name={'message'}
				value={formObj.message}
				onChange={handleChange}
				InputLabelProps={{ shrink: true }}/>
			<TextField
				fullWidth
				multiline
				label="Pre-fill mesej whatsapp"
				variant="outlined"
				margin="normal"
				rows={4}
				name={'whatsappMsg'}
				value={formObj.whatsappMsg}
				onChange={handleChange}
				InputLabelProps={{ shrink: true }}/>
		</>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InnerTabMessage)