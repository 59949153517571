import React from 'react'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

export default function HeadingBtn(props) {

	const {removeExistingTid, updateNotification} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<ButtonGroup
			orientation={isMobile ? 'vertical' : 'horizontal'}
			variant="outlined"
			color={'primary'}
			fullWidth={isMobile}>
			<Button color={'error'} startIcon={<CancelOutlinedIcon/>} onClick={removeExistingTid}>
				Batal sambungan
			</Button>
			<Button startIcon={<SaveOutlinedIcon/>} onClick={updateNotification}>
				Simpan
			</Button>
		</ButtonGroup>
	)
}