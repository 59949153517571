import React, { useEffect } from 'react'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

import redirectToPortalFunc from '../../functions/redirect-to-portal'

const StyledDiv = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
  `}
`

export default function Logout (props) {
  useEffect(() => {
    redirectToPortalFunc()
  }, [])

  return (
    <StyledDiv>
      <Alert severity={'info'}>
        Log keluar..
      </Alert>
    </StyledDiv>
  )
}
