import React, {useEffect, useState} from 'react'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import tabWrapperStyle from '../../../styles/tab-wrapper'

import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'

const StyledDiv = styled(Box)`
  ${({theme}) => `
    ${tabWrapperStyle(theme)}
    
    code {
      color: ${theme.palette.secondary.dark};
    }
  `}
`

export default function Form(props) {
	const {formObj, setFormObj, errCode, setErrCode} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [tabIndex, setTabIndex] = useState(0)

	useEffect(() => {

		if (errCode === 1) {
			setTabIndex(0)
		}

	}, [errCode])

	return (
		<StyledDiv>

			<Box className="tab-wrapper">
				<Tabs
					orientation={isMobile ? 'horizontal' : 'vertical'}
					variant="scrollable"
					value={tabIndex}
					onChange={(e, i) => setTabIndex(i)}
					className="tabs">
					<Tab label="Kata Kunci"/>
					<Tab label="Mesej"/>
					<Tab label="Tambahan"/>
				</Tabs>
				{
					tabIndex === 0 && <Tab1 formObj={formObj} setFormObj={setFormObj} errCode={errCode} setErrCode={setErrCode}/>
				}
				{
					tabIndex === 1 && (
						<Tab2 formObj={formObj} setFormObj={setFormObj}/>
					)
				}
				{
					tabIndex === 2 && (
						<Tab3 formObj={formObj} setFormObj={setFormObj}/>
					)
				}
			</Box>


		</StyledDiv>
	)
}
