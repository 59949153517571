import React from 'react'
import {connect} from 'react-redux'

import TabHeading from '../../../components/tab-heading'

const Heading = function Heading(props) {

	return (
		<TabHeading
			className="heading"
			title='Debug post'
			description='Semak apa yang Bizsaya lakukan pada komen di Facebook Post anda'
			titleProps={{ xs: 12 }}
			btnContainerProps={{ xs: 12 }}>
		</TabHeading>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Heading)