import React from 'react'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'


export default function BtnContainer(props) {

	const {openForm, setOpenForm, createNew, save} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<ButtonGroup orientation={isMobile? 'vertical' : 'horizontal'} fullWidth={isMobile}>
			{
				openForm ? (
					<>
						<Button
							variant={'outlined'}
							startIcon={<CancelOutlinedIcon/>}
							color={'warning'}
							onClick={() => setOpenForm(false)}>
							Batal
						</Button>
						<Button variant={'outlined'} startIcon={<SaveOutlinedIcon/>} onClick={save}>Simpan</Button>
					</>
				) : (
					<Button variant={'outlined'} startIcon={<AddCircleOutlineOutlinedIcon/>} onClick={createNew}>
						Tambah tetapan
					</Button>
				)
			}
		</ButtonGroup>
	)
}
