import React from 'react'

import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import FacebookIcon from '@mui/icons-material/QuestionAnswerOutlined'
import ProspekIcon from '@mui/icons-material/PermContactCalendarOutlined'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import WhatsappOutlinedIcon from '@mui/icons-material/WhatsappOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';

export default function MenuIcon(props) {

	const {to} = props

	if (to === '/') {
		return <DashboardIcon/>
	} else if (to === '/fb') {
		return <FacebookIcon/>
	} else if (to === '/ig') {
		return <PhotoCameraOutlinedIcon/>
	} else if (to === '/prospects') {
		return <ProspekIcon/>
	} else if (to === '/config') {
		return <SettingsIcon/>
	} else if (to === '/tutor') {
		return <HelpOutlineOutlinedIcon/>
	} else if (to === '/whatsapp') {
		return <WhatsappOutlinedIcon/>
	} else if (to === '/telegram') {
		return <PodcastsOutlinedIcon/>
	} else if (to === '/logout') {
		return <LogoutOutlinedIcon/>
	} else {
		return <DashboardIcon/>
	}

}