import React from 'react'
import _ from 'lodash'

import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import Copy from './copy'

export default function Paper1(props) {

	const {tId, fbId, updateThePage, setSnackbarMsg} = props

	function onCopy () {
		setSnackbarMsg('Kod arahan bagi fungsi notifikasi penting telah disalin secara automatik')
	}

	return (
		<Paper sx={{padding: 2}}>
			<Alert severity={'info'}>
				Salin kod arahan dibawah dan tampal ke <a href='https://t.me/Bizsaya_bot' target='_blank' rel='noreferrer'>Bizsaya Telegram Bot.</a>
				Anda akan menerima notifikasi penting berkenaan auto reply
				Facebook Page ini seperti terdapat ralat sewaktu Bizsaya
				auto reply komen ataupun tetapan baru telah ditetapkan ke Facebook Post.
			</Alert>

			{
				_.isEmpty(tId) ? (
					<Copy code={`Bizsaya link AR ${fbId}`} onCopy={onCopy} />
				) : (
					<Box mt={2}>
						<Button
							fullWidth
							variant='outlined'
							color='secondary'
							startIcon={<FileCopyOutlinedIcon />}
							onClick={() => updateThePage({ tId: null })}
						>
							Batalkan notifikasi penting
						</Button>
					</Box>
				)
			}

		</Paper>
	)
}