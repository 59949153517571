import React, {useState} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'

import {checkUrl} from '../../../../actions/facebook'

const StyledDialogContent = styled(DialogContent)`
  ${({theme}) => `
    
  `}
`

const StyledAlert = styled(Alert)`
  ${({theme}) => `
		margin-bottom: ${theme.spacing(2)};
	`}
`

const VerifyLink = function VerifyLink(props) {
	const {open, onClose, checkUrl} = props

	const [url, setUrl] = useState('')
	const [msg, setMsg] = useState('')
	const [success, setSuccess] = useState(false)

	function checkTheURL() {
		if (/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url)) {
			checkUrl(url)
				.then(response => {
					if (response.data.valid) {
						setSuccess(true)
					} else {
						setSuccess(false)
						setMsg('Pautan ini tidak boleh digunakan di Facebook. Sila tukar')
					}
				})
				.catch(err => {
					if (err.response) {
						console.error(err.response.data)
					} else {
						console.error(err)
					}
				})
		} else {
			setMsg('Pautan tidak sah. Sila semak pautan yang anda masukkan')
		}
	}

	function changeUrl (e) {
		setMsg('')
		setSuccess(false)
		setUrl(e.target.value)
	}

	function clickText () {
		setMsg('')
		setSuccess(false)
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Semak pautan ke Facebook</DialogTitle>
			<StyledDialogContent>
				<StyledAlert severity={'info'}>
					Semak pautan yang akan digunakan untuk auto reply ke Facebook.
					Ini bagi memastikan pautan tersebut tiada masalah dengan Facebook
				</StyledAlert>
				{
					success && (
						<StyledAlert severity={'success'}>
							Pautan ini boleh digunakan di Facebook
						</StyledAlert>
					)
				}
				<TextField
					fullWidth
					margin={'normal'}
					label="Pautan untuk disemak"
					variant="outlined"
					value={url}
					onClick={clickText}
					onChange={changeUrl}
					error={!_.isEmpty(_.trim(msg))}
					helperText={!_.isEmpty(_.trim(msg))? msg : 'Contoh : https://ws.bizsaya.com/0172631883'}/>
			</StyledDialogContent>
			<DialogActions>
				<Button onClick={onClose}>Tutup</Button>
				<Button onClick={checkTheURL}>Semak</Button>
			</DialogActions>
		</Dialog>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		checkUrl
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VerifyLink)