import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import homepage from './reducers/homepage'
import misc from './reducers/misc'
import account from './reducers/account'
import facebook from './reducers/facebook'
import instagram from './reducers/instagram'
import prospects from './reducers/prospects'
import photos from './reducers/photos'
import debug from './reducers/debug'

export default createStore(combineReducers({
  debug,
  homepage,
  misc,
  account,
  facebook,
  prospects,
  instagram,
  photos
}), applyMiddleware(thunk))
