import requester from './requester'
import {
  SET_HOMEPAGE
} from '../constant'

export const getHomepageDetail = () => dispatch => {
  return requester.get('/')
    .then(response => {
      dispatch({ type: SET_HOMEPAGE, payload: response.data })
    })
}

export const getStats = (params) => {
  return requester.get('/statistics', { params })
}
