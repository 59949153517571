import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'

import _, {isEmpty, isNull, isUndefined, trim} from 'lodash'
import Cookie from 'js-cookie'
import Pusher from 'pusher-js'
import dayjs from 'dayjs'

import {parse, stringify} from './functions/querystring'
import {getProfile, getRefreshedToken, getTokenExpiredIn} from './actions/profile'
import {ADD_NUMBER, SET_DEBUG, SET_MISC} from './constant'
import {getFacebookDetail} from './actions/facebook'
import {getInstaDetail} from './actions/instagram'
import {getNumbers, getProspect} from './actions/prospects'

const Bootstrap = function Bootstrap(props) {

  const {
    location, history, account, debug, getProfile, getTokenExpired, tokenExpiredAt, getRefreshedToken,
    setLoading, setSnackbarMsg,
    getFacebookDetail, getInstagramDetail, getProspect, addNumber, getNumbers, setDebugFB
  } = props

  useEffect(() => {
    const qs = parse(location.search.replace(/^\?/, ''))
    let sessionToken = null

    if (!isEmpty(trim(qs['?token'])) || !isEmpty(trim(qs.token))) {
      sessionToken = qs.token || qs['?token']

      Cookie.set('ssid', sessionToken)
      Cookie.remove('actig')
      Cookie.remove('actpg')
    } else {
      sessionToken = Cookie.get('ssid')

      if (isUndefined(sessionToken)) {
        // window.location.href = 'https://bizsaya.com'
      }
    }

    delete qs.token

    const path = location.pathname === '/logout' ? '/' : location.pathname
    history.push(`${path}?${stringify(qs)}`)

    getProfile(sessionToken)
      .then(profile => {
        const whatToPromise = [getProspect(), getNumbers()]

        if (!_.isEmpty(profile.FacebookPages)) {
          whatToPromise.push(getFacebookDetail())
        }
        if (!_.isEmpty(profile.InstagramInsta)) {
          whatToPromise.push(getInstagramDetail())
        }

        return Promise.all(whatToPromise)
      })
      .catch(() => {

      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(account)) {
      const pusher = new Pusher('483e8ec9c611ac3ef9fe', {
        cluster: 'ap1',
        encrypted: true
      })

      const channel = pusher.subscribe(`account_${account.id}`)

      channel.bind('evt', function (data) {
        const { type } = data
        if (type === 'autoReplySpecific' || type === 'autoReplyTag' || type === 'ignorePost') {
          setLoading(true)

          let msg = 'Post telah ditambah ke tetapan auto reply'
          if (type === 'autoReplyTag') {
            msg = 'Post telah ditambah ke tetapan auto reply TAG'
          } else if (type === 'ignorePost') {
            msg = 'Post baru akan diAbaikan daripada auto reply'
          }

          getFacebookDetail()
            .then(() => setSnackbarMsg(msg))
            .catch(() => { })
            .then(() => setLoading(false))
        } else if (type === 'prospect' || type === 'whatsappAdmin' || type === 'n2wlAdmin' || type === 'n2wlClient') {
          setLoading(true)

          let msg = 'Link telegram ke prospek telah dikemaskini'
          if (type === 'whatsappAdmin') {
            msg = 'Link telegram admin ke pautan whatsapp telah dikemaskini'
          } else if (type === 'n2wlAdmin') {
            msg = 'Link telegram admin ke kumpulan N2WL telah dikemaskini'
          } else if (type === 'n2wlClient') {
            msg = 'Link telegram agen ke kumpulan N2WL telah dikemaskini'
          }

          getProspect()
            .then(() => setSnackbarMsg(msg))
            .catch(() => {
            })
            .then(() => setLoading(false))
        } else if (type === 'number') {
          data.type = undefined
          addNumber(data)
          setSnackbarMsg('Nombor telefon baharu diterima')
        } else if (type === 'profile') {
          getProfile()
          getProspect()
          getFacebookDetail()
          setSnackbarMsg('Profil telah dikemaskini')
        } else if (type === 'debug') {
          const fbClone = JSON.parse(JSON.stringify(debug.fb))
          fbClone.push(data.msg)
          setDebugFB({ fb: fbClone })
        }
      })

      getTokenExpired()

      window.fbAsyncInit = function () {
        FB.init({
          appId: '402020664519636',
          cookie: true, // Enable cookies to allow the server to access the session.
          xfbml: false, // Parse social plugins on this webpage.
          version: 'v9.0' // Use this Graph API version for this call.
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id])

  useEffect(() => {
    if (!isNull(tokenExpiredAt)) {
      setTimeout(() => getRefreshedToken(), dayjs(tokenExpiredAt).diff(dayjs()) - 120000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpiredAt])

  return (
    <>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    account: state.account.detail,
    tokenExpiredAt: state.account.tokenExpiredAt,
    debug: state.debug
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getProfile: token => {
      return dispatch(getProfile(token))
    },
    getTokenExpired: () => {
      dispatch(getTokenExpiredIn())
    },
    getRefreshedToken: () => {
      dispatch(getRefreshedToken())
    },
    setLoading: (showLoading = false) => {
      dispatch({ type: SET_MISC, payload: { showLoading } })
    },
    setSnackbarMsg: (snackbarMessage = null) => {
      dispatch({ type: SET_MISC, payload: { snackbarMessage } })
    },
    getFacebookDetail: () => {
      return dispatch(getFacebookDetail())
    },
    getInstagramDetail: () => {
      return dispatch(getInstaDetail())
    },
    getProspect: () => {
      return dispatch(getProspect())
    },
    getNumbers: () => {
      return dispatch(getNumbers())
    },
    addNumber: payload => {
      return dispatch({ type: ADD_NUMBER, payload })
    },
    setDebugFB: payload => {
      dispatch({ type: SET_DEBUG, payload })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Bootstrap))