import React, {useState} from 'react'
import _ from 'lodash'

import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined'
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import AgentDialog from './agent-dialog'

const StyledBtnContainer = styled(Box)`
  ${({theme}) => `
    padding: ${theme.spacing(2)};
    display: flex;
    justify-content: flex-end;
  `}
`

const StyledAlert = styled(Alert)`
	${({theme}) => `
		padding: 0px ${theme.spacing(2)};
	`} 
`

export default function Tab2(props) {

	const {formObj, setFormObj, error, setError} = props
	const {agents} = formObj

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [agent, setAgent] = useState({})

	function addNewAgent() {
		setError(null)
		setAgent({
			name: '',
			number: '',
			message: '',
			order: agents.length,
			isNew: true
		})
	}

	function save() {
		const formObjClone = {...formObj}
		formObjClone.agents = JSON.parse(JSON.stringify(formObjClone.agents))

		const index = _.findIndex(formObjClone.agents, {number: agent.number})
		if (index === -1) {
			formObjClone.agents.push({...agent})
		} else {
			const agentToAdd = {...agent}
			agentToAdd.order = _.clone(formObjClone.agents[index].order)
			formObjClone.agents[index] = agentToAdd
		}

		setFormObj(formObjClone)
		setAgent({})
	}

	function editMe (agent) {
		const agentClone = {...agent}
		agentClone.isNew = false
		setAgent(agentClone)
	}
	
	function goUp (index) {
		const formObjClone = {...formObj}
		formObjClone.agents = JSON.parse(JSON.stringify(formObjClone.agents))
		
		const temp = {...formObjClone.agents[index - 1]}
		formObjClone.agents[index - 1] = {...formObjClone.agents[index]}
		formObjClone.agents[index] = temp

		resetOrder(formObjClone)
	}

	function goDown (index) {
		const formObjClone = {...formObj}
		formObjClone.agents = JSON.parse(JSON.stringify(formObjClone.agents))

		const temp = {...formObjClone.agents[index + 1]}
		formObjClone.agents[index + 1] = {...formObjClone.agents[index]}
		formObjClone.agents[index] = temp

		resetOrder(formObjClone)
	}

	function deleteThis (index) {
		const formObjClone = {...formObj}
		formObjClone.agents = JSON.parse(JSON.stringify(formObjClone.agents))
		formObjClone.agents.splice(index, 1)

		resetOrder(formObjClone)
	}

	function resetOrder (formObjClone) {
		let o = 0
		formObjClone.agents.map(a => {
			a.order = o
			o++
			return a
		})

		setFormObj(formObjClone)
	}

	return (
		<Box>
			<Alert severity={'info'}>
				Agen akan menerima whatsapp daripada prospek yang klik pautan whatsapp ini.
				Sekurang-kurangnya seorang agen adalah diperlukan.
				Sekiranya anda mempunyai lebih daripada seorang agen,
				Bizsaya akan menghantar prospek mengikut turutan. Klik pada nama agen dibawah untuk kemaskini maklumat agen
			</Alert>

			<StyledBtnContainer>
				<Button fullWidth={isMobile} variant={'outlined'} onClick={addNewAgent}>Tambah agen</Button>
			</StyledBtnContainer>

			{
				!_.isEmpty(formObj.agents) && (
					<List>
						{
							formObj.agents.map((a, i) => {
								return (
									<ListItem key={i} disablePadding secondaryAction={(
										<ButtonGroup
											variant="outlined"
											color={'primary'}
											size={'small'}>
											<IconButton disabled={a.order === 0} onClick={() => goUp(i)}>
												<ArrowCircleUpOutlinedIcon/>
											</IconButton>
											<IconButton disabled={a.order === (formObj.agents.length - 1)} onClick={() => goDown(i)}>
												<ArrowCircleDownOutlinedIcon/>
											</IconButton>
											<IconButton color={'error'} onClick={() => deleteThis(i)}>
												<DeleteOutlinedIcon/>
											</IconButton>
										</ButtonGroup>
									)}>
										<ListItemButton role={undefined} onClick={() => editMe(a)}>
											<ListItemAvatar>
												<Avatar sx={{bgcolor: 'transparent'}}>
													<AssignmentIndOutlinedIcon color={'primary'}/>
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={`${a.name} ${!_.isEmpty(_.trim(a.message)) ? '*' : ''}`}
												secondary={`+601${a.number}`}/>
										</ListItemButton>
									</ListItem>
								)
							})
						}
					</List>
				)
			}

			{
				error === 2 && (
					<StyledAlert severity={'error'}>
						Sekurang-kurangnya seorang agen adalah diperlukan. Sila klik pada tambah agen untuk menambah agen baru
					</StyledAlert>
				)
			}


			{
				!_.isEmpty(agent) && <AgentDialog agent={agent} setAgent={setAgent} save={save}/>
			}

		</Box>
	)
}
