import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import _ from 'lodash'

import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

const StyledAlert = styled(Alert)`
  ${({theme}) => `
    a {
    	text-decoration: none;
			color: ${theme.palette.info.main};
    }
  `}
`

const StyledDiv = styled(Box)`
  ${({theme}) => `
    padding: 0px ${theme.spacing(2)};
  `}
`

export default function Notification(props) {

	const {code, setSnackbarMsg, formObj, removeTid} = props

	return (
		<Box>

			{
				_.isEmpty(_.trim(code)) ? (
					<StyledAlert severity={'warning'}>
						Ini adalah tetapan pautan whatsapp baru. Sila tambah tetapan ini terlebih dahulu sebelum membuat notifikasi admin
					</StyledAlert>
				) : (
					<StyledAlert severity={'info'}>
						Salin kod arahan dibawah dan tampal kod arahan tersebut ke&nbsp;
						<a href="https://t.me/Bizsaya_bot" target="_blank" rel="noreferrer">BOT Telegram Bizsaya</a>.
						Anda juga boleh tampal kod arahan tersebut didalam
						group Telegram perniagaan anda. Pastikan anda telah add BOT Telegram Bizsaya terlebih dahulu ke group
						Telegram perniagaan anda.
					</StyledAlert>
				)
			}

			{
				!_.isEmpty(_.trim(code)) && (
					<StyledDiv>
						{
							!_.isEmpty(formObj.tId) ? (
								<Button sx={{mt: 3}} fullWidth color={'error'} variant={'outlined'} onClick={removeTid}>
									Batalkan sambungan notifikasi admin
								</Button>
							) : (
								<TextField
									sx={{mt: 3}}
									fullWidth
									value={code}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<CopyToClipboard text={code} onCopy={() => setSnackbarMsg('Kod arahan telah disalin')}>
													<Tooltip title={'Salin kod arahan secara automatik'}>
														<IconButton edge="end">
															<FileCopyOutlinedIcon/>
														</IconButton>
													</Tooltip>
												</CopyToClipboard>
											</InputAdornment>
										)
									}}
									label="Kod arahan"
								/>
							)
						}
					</StyledDiv>
				)
			}

		</Box>
	)
}
