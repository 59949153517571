import React, { useEffect, useState } from 'react'

import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'

const StyledDialogContent = styled(DialogContent)`
  ${({ theme }) => `
    padding-right: 0px;  
    padding-left: 0px;
    min-width: 320px;  
  
    .list-page-selector {
      padding-right: 170px;
    }
  
  `}
`

export default function ChangeIgDialog (props) {
  const { insta, activeIg, onClose, changeActivePage } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [pagesToLoop, setPagesToLoop] = useState([])

  useEffect(() => {
    const pagesToBeLoop = []
    insta.forEach(page => {
      if (page.pageId === activeIg.pageId || !page.isConnected) {
        return
      }

      pagesToBeLoop.push(page)
    })

    setPagesToLoop(pagesToBeLoop)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insta, activeIg])

  return (
    <Dialog open fullScreen={isMobile} onClose={onClose}>
      <DialogTitle>Tukar Instagram</DialogTitle>
      <StyledDialogContent>
        <List>
          <ListItem divider>
            <ListItemAvatar>
              <Avatar src={`https://graph.facebook.com/v2.10/${activeIg.pageId}/picture`} />
            </ListItemAvatar>
            <ListItemText
              primary={activeIg.igName}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>
          {
            pagesToLoop.map(igObject => {
              if (igObject.igId === activeIg.igId) {
                return null
              }

              return (
                <ListItem
                  button
                  divider
                  className='list-page-selector'
                  key={igObject.id}
                >
                  <ListItemAvatar>
                    <Avatar src={`https://graph.facebook.com/v2.10/${igObject.pageId}/picture`} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={igObject.igName}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                  <ListItemSecondaryAction>
                    {
                      igObject.igId !== activeIg.igId && (
                        <Button
                          color='primary'
                          variant='outlined'
                          startIcon={<CheckCircleOutlinedIcon />}
                          onClick={() => changeActivePage(igObject)}
                        >
                          Aktifkan
                        </Button>
                      )
                    }
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          }
        </List>
      </StyledDialogContent>
      <DialogActions>
        <Button
          fullWidth={isMobile}
          variant='outlined'
          onClick={onClose}
        >
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  )
}
