import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import { getAuthLink } from '../../../actions/instagram'
import { addIg, deleteIgs } from '../../../actions/configuration'
import {SET_MISC} from '../../../constant'
import {getProfile} from '../../../actions/profile'

const HeadingBtn = function HeadingBtn(props) {

	const {setLoading, instagramToAdd, setInstagramToAdd, instagramToDelete, setInstagramToDelete, getProfile} = props

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	function reAuth () {
		setLoading(true)
		getAuthLink('reauth')
			.then(response => {
				if (response.data && response.data.url) {
					window.location.href = response.data.url
				}
			})
			.catch(err => console.error(err))
	}

	function save () {
		if (!_.isEmpty(instagramToAdd)) {
			setLoading(true)
			addIg(instagramToAdd)
				.then(() => setInstagramToAdd([]))
				.then(() => getProfile())
				.catch(err => { })
			.then(() => setLoading(false))
		}
		if (!_.isEmpty(instagramToDelete)) {
			setLoading(true)
			deleteIgs(instagramToDelete)
				.then(() => setInstagramToDelete([]))
				.then(() => getProfile())
				.catch(err => { })
			.then(() => setLoading(false))
		}
	}

	return (
		<ButtonGroup orientation={isMobile? 'vertical' : 'horizontal'} fullWidth={isMobile} variant="outlined" color={'primary'}>
			<Button color={'secondary'} startIcon={<AutorenewOutlinedIcon />} onClick={reAuth}>
				Perbaharui akses
			</Button>
			<Button
				startIcon={<SaveOutlinedIcon />}
				onClick={save}
				disabled={_.isEmpty(instagramToAdd) && _.isEmpty(instagramToDelete)}>
				Simpan
			</Button>
		</ButtonGroup>
	)
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		setLoading: (showLoading = false) => {
			dispatch({type: SET_MISC, payload: {showLoading}})
		},
		getProfile: () => {
			dispatch(getProfile())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeadingBtn)